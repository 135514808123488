import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { detectConfig } from "providers/ConfigProvider";

const { apiUrl } = detectConfig();

async function fetchData(path, options) {
  const res = await fetch(`${apiUrl}${path}`, {
    method: "GET",
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
  });
  const data = await res.json();
  if (data.error) {
    throw new Error(data.error.message);
  }
  return data;
}

export const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function Disconnect() {
  const auth = useAuth();
  useEffect(() => {
    auth.logout();
  }, [auth]);
  return <></>;
}

export function AuthGuard({
  children,
  connected,
  redirectTo,
  forceLogout = false,
}) {
  const auth = useAuth();
  const navigate = useNavigate();

  const passes = (auth.user && connected) || (!auth.user && !connected);
  const shouldBeDisconnected = auth.user && !connected && forceLogout;

  useEffect(() => {
    if (shouldBeDisconnected) {
      auth.logout(false);
    } else if (!passes && redirectTo) {
      navigate(redirectTo);
    }
  }, [passes, redirectTo, navigate, shouldBeDisconnected, auth]);

  return passes && !shouldBeDisconnected ? children : <></>;
}

AuthGuard.propTypes = {
  children: PropTypes.any.isRequired,
  connected: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
  forceLogout: PropTypes.bool,
};

export default function AuthProvider({ children }) {
  const [token, setToken] = useLocalStorage("token", null);
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  useEffect(() => {
    // Properly handle token expiration.
    // Here the token cannot be refreshed, when expired, we log out the user.
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 <= Date.now()) {
        setToken(null);
        setUser(null);
      }
    }
  }, [token, setToken, setUser]);

  const login = async (
    identifier,
    password,
    { simulationId, simulationCode } = {},
  ) => {
    const authData = await fetchData("/api/auth/local", {
      method: "POST",
      body: JSON.stringify({
        identifier,
        password,
        simulationId,
        simulationCode,
      }),
    });
    const userData = await fetchData("/api/users/me?populate=role", {
      headers: {
        Authorization: `Bearer ${authData.jwt}`,
      },
    });
    setToken(authData.jwt);
    setUser(userData);
    navigate("/");
  };

  const logout = (redirect = true) => {
    setToken(null);
    setUser(null);
    if (redirect && window.location.pathname !== "/login") {
      navigate("/login");
    }
  };

  const value = {
    token,
    user,
    role: user?.role?.name,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
