import React, { useCallback } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { useConfig } from "providers/ConfigProvider";
import useFetchApi from "hooks/useFetchApi";
import Main from "components/Main";
import Status from "components/Status";
import btnBack from "images/icons/back.svg";
import Beneficiaire from "components/MyFileTabs/Beneficiaire";
import Projet from "components/MyFileTabs/Projet";
import Chantier from "components/MyFileTabs/Chantier";
import Documents from "components/MyFileTabs/Documents";
import Messagerie from "components/MyFileTabs/Messagerie";
import UnimplementedPage from "components/UnimplementedPage";

import useSWR, { mutate } from "swr";
import Loader from "components/Loader";

const tabTitles = {
  beneficiaire: "Bénéficiaire",
  projet: "Projet",
  chantier: "Chantier",
  documents: "Documents",
  messagerie: "Messagerie",
};

export default function MyFilesDetails() {
  const [fetchApi] = useFetchApi();
  const { isCustomerUrl } = useConfig();
  const { fileID, tab } = useParams();
  const role = useOutletContext();
  const isManager = !isCustomerUrl && role && role !== "Client";
  const navigate = useNavigate();

  const defaultTab = isManager ? "beneficiaire" : "projet";
  const currentTab = tab ?? defaultTab;
  const setCurrentTab = (tab) => {
    navigate(
      `/${isManager ? "dossiers" : "mes-projets"}/${fileID}${tab !== defaultTab ? "/" + tab : ""}`,
    );
  };

  const { data, error, isLoading } = useSWR(
    `dossiers/${fileID}?populate=deep,3`,
    fetchApi,
  );

  // Function to trigger a re-fetch
  const refreshData = useCallback(() => {
    mutate(`dossiers/${fileID}?populate=deep,3`);
  }, [fileID]);

  const handleOnClickBack = useCallback(
    () => navigate("/dossiers", { replace: true }),
    [navigate],
  );

  function SwitchTabs(title, statut) {
    switch (title) {
      case "beneficiaire":
        return (
          <Beneficiaire
            id={+fileID}
            data={data}
            fetchData={refreshData}
            role={role}
          />
        );

      case "projet":
        return (
          <Projet
            id={+fileID}
            data={data}
            fetchData={refreshData}
            role={role}
          />
        );

      case "chantier":
        return (
          <Chantier
            id={+fileID}
            data={data}
            fetchData={refreshData}
            role={role}
          />
        );

      case "documents":
        return <Documents id={+fileID} data={data} statut={statut} />;

      case "messagerie":
        return (
          <Messagerie
            id={+fileID}
            data={data}
            statut={statut}
            fetchData={refreshData}
          />
        );

      default:
        return <UnimplementedPage title={currentTab} />;
    }
  }

  if (!data) {
    return (
      <div className="w-full flex-1 flex justify-center items-center">
        <Loader isLoading={isLoading} error={error} />
      </div>
    );
  } else {
    const fileData = {
      statut: data?.data?.attributes?.statut,
      reference: data?.data?.attributes?.reference,
      title: data?.data?.attributes?.simulation?.data?.attributes?.operation,
    };
    const isConsultation = role === "Consultation";
    const tabs = isManager
      ? isConsultation
        ? ["beneficiaire", "chantier", "documents"]
        : ["beneficiaire", "chantier", "documents", "messagerie"]
      : ["projet", "documents", "messagerie"];

    return (
      <Main
        title={
          <>
            <button className="pr-3" onClick={handleOnClickBack}>
              <img src={btnBack} alt="retour" />
            </button>
            {isManager ? (
              <>
                Vue détaillée dossier REF {fileID} :
                <span className="font-normal"> {fileData.reference}</span>
              </>
            ) : (
              <>
                Vue détaillée du projet :
                <span className="font-normal"> {fileData.title}</span>
              </>
            )}
          </>
        }
      >
        <Status
          status={fileData.statut}
          id={+fileID}
          role={role}
          data={data}
          fetchData={refreshData}
        />

        <div className="-mx-4 mt-2 border-b-2 pb-1">
          {tabs.map((item) => (
            <button
              key={item}
              className={
                currentTab === item
                  ? "inline mx-4 text-main-color font-medium underline underline-offset-10"
                  : "inline mx-4 font-medium text-[#9E9E9E]"
              }
              onClick={() => {
                if (item !== currentTab) {
                  // Actualisation de l'onglet messagerie ou documents au cas où de nouveaux éléments auraient été ajoutés.
                  if (item === "messagerie" || item === "documents") {
                    refreshData();
                  }
                  setCurrentTab(item);
                }
              }}
            >
              {tabTitles[item]}
            </button>
          ))}
        </div>

        {SwitchTabs(currentTab, fileData.statut)}
      </Main>
    );
  }
}
