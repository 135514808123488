import React, { useCallback, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "components/AuthProvider";
import DocumentList from "components/MyFileTabs/DocumentList";
import RaiDialog from "components/MyFileTabs/RaiDialog";
import AhDialog from "components/MyFileTabs/AhDialog";
import FileUploader from "components/MyFileTabs/FileUploader";
import MultiFileUploader from "components/MyFileTabs/MultiFileUploader";
import useFetchApi from "hooks/useFetchApi";

function Documents({ id, data, statut }) {
  const { isCustomerUrl } = useConfig();
  const [fetchApi] = useFetchApi();
  const [filesData, setFilesData] = useState([]);
  const auth = useAuth();
  const role = auth?.user?.role?.name;
  const isManager = !isCustomerUrl && role && role !== "Client";
  const [raiDialogOpen, setRaiDialogOpen] = useState(false);
  const [ahDialogOpen, setAhDialogOpen] = useState(false);
  const dossierClientId = data.data?.attributes?.client?.data?.id;

  const clientFilesData = useMemo(
    () =>
      filesData.filter((file) => file.attributes.upload_by === dossierClientId),
    [dossierClientId, filesData],
  );

  const managerFilesData = useMemo(
    () =>
      filesData.filter((file) => file.attributes.upload_by !== dossierClientId),
    [dossierClientId, filesData],
  );

  const openModal = (type) => {
    if (type === "RAI") {
      setRaiDialogOpen(true);
    } else if (type === "AH") {
      setAhDialogOpen(true);
    }
  };

  const closeModal = (type) => {
    if (type === "RAI") {
      setRaiDialogOpen(false);
    } else if (type === "AH") {
      setAhDialogOpen(false);
    }
  };

  const fetchFilesData = useCallback(async () => {
    try {
      const apiUrl = `documents?filters[dossier][id][$eq]=${id}`;
      const response = await fetchApi(apiUrl);
      setFilesData(response.data);
    } catch (error) {
      // console.error("Error fetching files data:", error);
    }
  }, [id, fetchApi]);

  useEffect(() => {
    fetchFilesData();
  }, [fetchFilesData]);

  const showAHButton = [
    "RAI envoyé",
    "Travaux en cours",
    "Travaux terminés",
    "Documents reçus et à traiter",
  ].includes(statut);

  return (
    <div>
      {raiDialogOpen && (
        <RaiDialog
          onClose={() => {
            closeModal("RAI");
          }}
          isOpen={raiDialogOpen}
          id={id}
          fetchFilesData={fetchFilesData}
          data={data}
        />
      )}

      {ahDialogOpen && (
        <AhDialog
          onClose={() => {
            closeModal("AH");
          }}
          isOpen={ahDialogOpen}
          id={id}
          fetchFilesData={fetchFilesData}
          data={data}
        />
      )}

      {isManager && (
        <FileUploader id={id} data={data} fetchFilesData={fetchFilesData} />
      )}
      {!isManager && (
        <MultiFileUploader
          dossier={data.data}
          files={clientFilesData}
          fetchFilesData={fetchFilesData}
        />
      )}

      <div className="w-full mt-4 flex flex-row-reverse">
        {isManager && statut === "Devis validé" && role !== "Consultation" && (
          <button
            onClick={() => {
              openModal("RAI");
            }}
            className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150"
          >
            Générer le RAI
          </button>
        )}

        {isManager && showAHButton && role !== "Consultation" && (
          <button
            onClick={() => {
              openModal("AH");
            }}
            className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150"
          >
            Générer l&apos;attestation sur l&apos;honneur
          </button>
        )}
      </div>

      <div className="mt-4">
        <DocumentList
          title={isManager ? "Fichiers attachés" : "Mes documents"}
          dossier={data.data}
          documents={clientFilesData}
          fetchFilesData={fetchFilesData}
          id={id}
        />
      </div>

      <div className="mt-8">
        <DocumentList
          title="Les documents Sorégies"
          sendButton
          withState={false}
          dossier={data.data}
          documents={managerFilesData}
          fetchFilesData={fetchFilesData}
          id={id}
        />
      </div>
    </div>
  );
}
Documents.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  statut: PropTypes.string.isRequired,
};

export default Documents;
