import React, { useState } from "react";
import { Navigate, Link, useOutletContext } from "react-router-dom";
import { fetchApi } from "../../fetchApi";
import useSWR, { mutate } from "swr";
import { useAuth } from "./../AuthProvider";
import { ReactComponent as BackIcon } from "../../images/icons/black-back.svg";
import iconEdit from "../../images/icons/edit.svg";
import iconCancel from "../../images/icons/cancel.svg";
import iconConfirm from "../../images/icons/confirm.svg";

export function UtilisateursDetails({ userID }) {
  const role = useOutletContext();
  const auth = useAuth();
  const [edit, setEdit] = useState(false);
  const [body, setBody] = useState();
  const { data, error, isLoading } = useSWR(
    `/api/users/${userID}?populate=role`,
    (url) =>
      fetchApi(url, { token: auth.token }).then(
        console.log("data", data, "error", error, "loading", isLoading),
        console.clear(),
      ),
  );
  const [initSuccess, setInitSuccess] = useState(false);
  const [initError, setInitError] = useState("");

  // const onSubmit = () => {
  //   console.log('body', body)
  // }

  const onCancel = () => {
    setEdit(false);
    // console.log('cancel')
  };

  const initPassword = async () => {
    const body = { email: data.email };
    try {
      setInitSuccess(false);
      setInitError("");
      await fetchApi("/api/auth/forgot-password", {
        token: auth.token,
        body,
      });
      // Trigger a revalidation (refetch).
      mutate("/api/auth/local");
    } catch ({ message }) {
      setInitError(message);
      // console.log(message)
    } finally {
      setInitSuccess(true);
    }
  };

  const handleBody = (e) => {
    if (e.target.value) {
      setBody((prevBody) => ({
        ...prevBody,
        [e.target.name]:
          e.target.name === "role"
            ? Number(e.target.value)
            : e.target.value === "true",
      }));
    }
  };

  const updateUserDetails = async () => {
    try {
      setInitSuccess(false);
      setInitError("");
      await fetchApi(
        `/api/users/${userID}`,
        { token: auth.token, body },
        "PUT",
      );
      // Trigger a revalidation (refetch).
      mutate(`/api/users/${userID}?populate=role`);
    } catch ({ message }) {
      setInitError(message);
      // console.log(message)
    } finally {
      // setInitSuccess(true);
      setEdit(false);
    }
  };

  function Block({ label, value }) {
    return (
      <div className="my-3 text-xs">
        <div className="text-sm text-main-color">{label}</div>
        <div className="mt-2 bg-white p-2 rounded-l border-0.5 border-stone-400">
          {value || "-"}
        </div>
      </div>
    );
  }

  function BlockSelect({ label, value, name, datavalue }) {
    const selectOptions = {
      role: [
        { value: 5, label: "Consultation" },
        { value: 3, label: "Administrateur" },
        { value: 4, label: "Gestionnaire" },
      ],
      blocked: [
        { value: false, label: "Actif" },
        { value: true, label: "Inactif" },
      ],
    };

    const getLabelFromValue = (fieldName, value) => {
      const options = selectOptions[fieldName];
      const matchingOption = options.find((option) => option.value === value);

      return matchingOption ? matchingOption.label : "-";
    };

    return (
      <div className="my-3 text-xs">
        <div className="text-sm text-main-color">{label}</div>
        <div
          className={`mt-2 bg-white p-2 rounded-l ${edit ? "border border-blue" : "border-0.5 border-stone-400"}`}
        >
          {edit && (
            <select
              className="w-full"
              name={name}
              onChange={handleBody}
              defaultValue={value === undefined ? datavalue : value}
            >
              {/* <option disabled={value} selected={!value} defaultValue>{datavalue}</option> */}
              {selectOptions[name].map((option) => (
                <option key={option.value} value={option.value} name={name}>
                  {option.label}
                </option>
              ))}
            </select>
          )}

          {!edit && <div>{getLabelFromValue(name, datavalue)}</div>}
        </div>
      </div>
    );
  }

  if (!userID || (!isLoading && !data)) {
    return <Navigate to="/parametres/utilisateurs" />;
  } else {
    // console.log(data)
    // console.log(body)
    return (
      <div className="w-full overflow-y-auto">
        <div className="p-4 w-[500px] mx-2 mb-4">
          {/* {body} */}
          <div className="flex justify-between">
            <Link
              to="/parametres/utilisateurs"
              className="flex font-semibold text-lg flex items-center mb-3"
            >
              <BackIcon className="mr-2" />
              Fiche utilisateur
            </Link>
            <div>
              {edit && (
                <div className="flex space-x-1">
                  <button onClick={updateUserDetails}>
                    <img src={iconConfirm} alt="confirmer" />
                  </button>
                  <button onClick={onCancel}>
                    <img src={iconCancel} alt="annuler" />
                  </button>
                </div>
              )}

              {!edit && role !== "Consultation" && (
                <button onClick={() => setEdit(!edit)}>
                  <img src={iconEdit} alt="modifier" />
                </button>
              )}
            </div>
          </div>

          <Block label="Prénom" value={data?.firstname} />
          <Block label="Nom" value={data?.name} />
          <Block label="E-mail" value={data?.email} />
          <Block label="Numéro de téléphone" value={data?.telephone} />
          <BlockSelect
            label="Rôle"
            value={body?.role}
            datavalue={data?.role.id}
            name="role"
          />
          <BlockSelect
            label="Statut"
            value={body?.blocked}
            datavalue={data?.blocked}
            name="blocked"
          />
          <button className="text-xs mt-2 underline" onClick={initPassword}>
            Réinitialiser le mot de passe
          </button>
          {initSuccess && (
            <p>
              <span className="text-xs text-green">
                Un e-mail pour réinitialiser le mot de passe a été envoyé.
              </span>
            </p>
          )}
          {initError && (
            <p>
              <span className="text-xs text-red">
                Une erreur s'est produite!
              </span>
            </p>
          )}
        </div>
      </div>
    );
  }
}
