import React from "react";
import PropTypes from "prop-types";
import DataTable from "components/DataTable";
import { getStatusBackground, formatDate, formatAmount } from "functions";

function transformApiResponse(res) {
  // We transform the API response to a format that is easier to use and
  // has a lower memory footprint than the full API response.
  return {
    ...res,
    data: res?.data?.map(({ id, attributes: item }) => {
      return {
        id,
        ...item,
        operation: item.operation,
        adresse: item.adresse_chantier,
        ville: item.ville_chantier,
        montant_travaux: item.montant_travaux, // cout travaux
        primeSoregies: item.prime_cee, // prime soregies
        prime_mpr: item.prime_mpr,
        reste_a_payer: item.reste_a_payer,
        energie_de_chauffage: item.energie_de_chauffage,
        type_de_logement: item.type_de_logement,
        surface: item.surface_logement,
        color: item.couleur_du_menage,
        dateCreation: item.createdAt,
        beneficiaireNom: item.nom,
        beneficiairePrenom: item.prenom,
        codePostal: item.code_postal,
        status: item.statut,
        dateMiseAJour: item.updatedAt,
        email: item.email,
        telephone: item.telephone,
        volume: undefined, // TODO: Add volume currently once available in API.
        fosts: item?.fosts?.[0],
      };
    }),
  };
}

const columns = [
  {
    label: "Opération",
    key: "nom_offre",
    selectOptionLabel: "Sélectionner une ou plusieurs opérations",
    filterField: "nom_offre",
    className: "max-w-[180px]",
    format: (nomOffre) => (
      <div
        className="truncate text-black text-ellipsis capitalize"
        title={nomOffre}
      >
        {nomOffre}
      </div>
    ),
    sortOptions: (offre1, offre2) => (offre1 ?? "").localeCompare(offre2 ?? ""),
  },
  {
    label: "Adresse du chantier",
    key: "adresse",
    selectOptionLabel: "Sélectionner une ou plusieurs adresses de chantier",
    filterField: "adresse_chantier",
    className: "max-w-[180px]",
    onlyCustomer: true,
    format: (adresse, row) => (
      <span
        className="truncate text-ellipsis"
        title={`${adresse}, ${row.ville}`}
      >
        {adresse}
        <br />
        {row.ville}
      </span>
    ),
    sortOptions: (addressA, addressB) => {
      // Tri par nom de rue, puis par numéro
      const [numA, , streetA] = addressA.split(/\s+/, 3);
      const [numB, , streetB] = addressB.split(/\s+/, 3);
      return (
        streetA?.localeCompare(streetB) ||
        +numA - +numB ||
        addressA.localeCompare(addressB)
      );
    },
  },
  {
    label: "Coût des travaux",
    key: "montant_travaux",
    onlyCustomer: true,
    format: formatAmount,
  },
  {
    label: "Prime Sorégies",
    key: "primeSoregies",
    onlyCustomer: true,
    sortFields: ["prime_cee"],
    defaultDirection: "desc",
    format: formatAmount,
  },
  {
    label: "Prime MPR",
    key: "prime_mpr",
    onlyCustomer: true,
    sortFields: ["prime_mpr"],
    defaultDirection: "desc",
    format: formatAmount,
  },
  {
    label: "Reste à payer",
    key: "reste_a_payer",
    onlyCustomer: true,
    format: formatAmount,
  },
  {
    label: "Energie de chauffage",
    key: "energie_de_chauffage",
    onlyCustomer: true,
  },
  {
    label: "Type de logement",
    key: "type_de_logement",
    onlyCustomer: true,
  },
  {
    label: "Surface",
    key: "surface",
    onlyCustomer: true,
    sortFields: ["surface_logement"],
    defaultDirection: "desc",
    format: (surface) => (
      <div className="pl-4">{surface ? `${surface} m²` : "-"}</div>
    ),
  },
  {
    label: "Créé le",
    key: "dateCreation",
    sortFields: ["createdAt"],
    defaultDirection: "desc",
    onlyCustomer: true,
    format: formatDate,
  },
  {
    label: "Statut",
    key: "status",
    selectOptionLabel: "Sélectionner un ou plusieurs statuts",
    filterField: "statut",
    onlyManager: true,
    format: (status) => (
      <div
        className={`px-2.5 py-1.5 rounded text-white ${getStatusBackground(
          status,
        )}`}
      >
        {status}
      </div>
    ),
  },
  {
    label: "Bénéficiaire",
    key: "beneficiaire",
    onlyManager: true,
    sortFields: ["nom", "prenom"],
    defaultDirection: "asc",
    format: (_, row) => (
      <div className="flex flex-col">
        <span>{row.beneficiairePrenom}</span>
        <span>{row.beneficiaireNom}</span>
      </div>
    ),
  },
  {
    label: "Email",
    key: "email",
    onlyManager: true,
  },
  {
    label: "Téléphone",
    key: "telephone",
    onlyManager: true,
  },
  {
    label: "Code postal",
    key: "codePostal",
    onlyManager: true,
  },
  {
    label: "Créé le",
    key: "dateCreation",
    sortFields: ["createdAt"],
    defaultDirection: "desc",
    onlyManager: true,
    format: formatDate,
  },
  {
    onlyManager: true,
    key: "actions",
    format: (_, row) => (
      <a
        className="underline text-black"
        href={`/simulations-clients/${row.id}`}
      >
        Voir détails
      </a>
    ),
  },
];

export default function SimulationList({
  setFilters,
  filters,
  title,
  pageSize,
  flex,
  headers,
  noFilter,
  noSort,
  pagination,
  footer,
  selectedId,
  setSelectedId,
  extraParams,
}) {
  const defaultActiveSort = "dateCreation";
  return (
    <DataTable
      entity="simulation"
      endpointUrl="simulations"
      formatData={transformApiResponse}
      columns={columns}
      defaultActiveSort={defaultActiveSort}
      setFilters={setFilters}
      filters={filters}
      noFilter={noFilter}
      noSort={noSort}
      title={title}
      pageSize={pageSize}
      flex={flex}
      headers={headers}
      pagination={pagination}
      footer={footer}
      setSelectedId={setSelectedId}
      selectedId={selectedId}
      extraParams={extraParams}
    />
  );
}
SimulationList.defaultProps = {
  pageSize: 10,
  flex: true,
  headers: true,
  noFilter: false,
  noSort: false,
  pagination: true,
  filters: {},
};
SimulationList.propTypes = {
  title: PropTypes.any,
  setFilters: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  pageSize: PropTypes.number,
  flex: PropTypes.bool,
  headers: PropTypes.bool,
  noFilter: PropTypes.bool,
  noSort: PropTypes.bool,
  pagination: PropTypes.bool,
  footer: PropTypes.any,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  extraParams: PropTypes.instanceOf(Object),
};
