import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useAuth } from "components/AuthProvider";
import { useApiEndpoint } from "ApiEndpointContext";
import { translateMessage } from "functions";

export default function FileUploader({ id, data, fetchFilesData }) {
  const apiEndpoint = useApiEndpoint();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const auth = useAuth();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const pdfFiles = acceptedFiles.filter(
        (file) => file.type === "application/pdf",
      );

      const formData = new FormData();
      pdfFiles.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });

      try {
        setLoading(true);
        const apiUrl = `${apiEndpoint}/api/dossier/upload-documents/${id}`;
        await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setSuccess(true);
      } catch (error) {
        setError(translateMessage(error.message));
      } finally {
        setLoading(false);
        fetchFilesData();
      }
    },
    [id, auth.token, apiEndpoint, fetchFilesData],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div className="mt-4 flex justify-center items-center h-[150px]">
      {!loading && (
        <div className="border border-blue text-main-color text-l rounded-xl w-full p-8 border-dashed mt-8">
          <div
            {...getRootProps()}
            className={`dropzone w-full flex flex-col justify-center items-center ${
              isDragActive ? "active" : ""
            }`}
          >
            <input {...getInputProps()} />
            <p>Glissez un ou plusieurs PDF ici, ou</p>
            <button className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150">
              Parcourir vos fichiers
            </button>
          </div>
        </div>
      )}

      {loading && (
        <div className="border border-blue text-main-color text-l rounded-xl w-full p-8 border-dashed mt-8">
          <div
            className={`dropzone w-full flex flex-col justify-center items-center ${
              isDragActive ? "active" : ""
            }`}
          >
            <input {...getInputProps()} className="hidden" />
            <p>{error ? "Une erreur est survenue." : "Veuillez patienter"}</p>
            {error ? (
              <button className="bg-red rounded text-white text-sm px-3 py-2 my-3 font-semibold">
                Réessayer
              </button>
            ) : (
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150 cursor-not-allowed"
                disabled=""
              >
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Téléchargement du fichier... {success && "OK"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
FileUploader.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  fetchFilesData: PropTypes.func.isRequired,
};
