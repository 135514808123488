// TODO: Remplacer par useFetchApi

import { detectConfig } from "providers/ConfigProvider";

const { apiUrl } = detectConfig();

// const errorTranslations = {
//   "Invalid identifier or password": "Identifiant ou mot de passe incorrect.",
//   "Failed to fetch": "Impossible de se connecter au serveur.",
//   Forbidden: "Accès refusé.",
// };

export async function fetchApi(
  apiPath,
  { token, body, data, type, parsed },
  method,
) {
  // console.log('fetchAPI', 'type',type, 'body',body, 'data', data, 'token',token)
  const requestBody = {
    method: method || (body || data ? "POST" : "GET"),
    headers: {
      "Content-Type": type || "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    body: parsed ? body : body ? JSON.stringify(body) : undefined,
    data: data || undefined,
  };

  // console.log('request', requestBody);

  try {
    const res = await fetch(`${apiUrl}${apiPath}`, requestBody);
    const result = await res.json();
    if (res.ok) {
      return result;
    }
    console.log(result);
    // throw new Error(result.error.message);
  } catch (error) {
    console.log("error", error);
    // return message
    // throw new Error(
    //   message in errorTranslations
    //     ? errorTranslations[message]
    //     : message ?? "Une erreur est survenue."
    // );
  }
}
