import React from "react";
import { useOutletContext } from "react-router-dom";
import { useConfig } from "providers/ConfigProvider";
import LastViewFiles from "components/LastViewFiles";
import HomeNews from "components/HomeNews";
import Infographic from "components/Indicateur/Infographic";
import Main from "components/Main";
import LastViewSimulations from "components/LastViewSimulations";

export default function Home() {
  const { isCustomerUrl } = useConfig();
  const role = useOutletContext();
  const isManager = !isCustomerUrl && role && role !== "Client";
  return (
    <div className="flex flex-1 flex-row gap-4 overflow-y-hidden">
      <Main className="flex-1">
        {!isManager && <LastViewSimulations />}
        <LastViewFiles />
        {isManager && <Infographic layout="home" />}
      </Main>
      {isManager && (
        <Main className="flex-0">
          <HomeNews />
        </Main>
      )}
    </div>
  );
}
