import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "components/AuthProvider";
import DossierList from "components/DossierList";
import Main from "components/Main";
import Search from "components/Search";

export default function Dossiers({ archive }) {
  const { isCustomerUrl } = useConfig();
  const [search, setSearch] = useState("");
  const { role } = useAuth();

  const isManager = !isCustomerUrl && role && role !== "Client";

  const setFilters = useCallback(() => {
    if (search) {
      const cleanSearch = search.trim();
      const searchWords = cleanSearch.split(/\s+/).filter((str) => str !== "");
      if (isManager) {
        // Recherche par nom et prénom du bénéficiaire ou par référence
        return {
          $or: [
            {
              reference: { $containsi: cleanSearch },
            },
            {
              $and: searchWords.map((word) => ({
                $or: [
                  { beneficiaire: { nom: { $containsi: word } } },
                  { beneficiaire: { prenom: { $containsi: word } } },
                ],
              })),
            },
          ],
        };
      } else {
        // Recherche par type d'opération ou adresse du chantier
        return {
          simulation: {
            $or: [
              { operation: { $containsi: cleanSearch } },
              { adresse_chantier: { $containsi: cleanSearch } },
            ],
          },
        };
      }
    }
    return {};
  }, [isManager, search]);

  return (
    <Main
      title={isManager ? "Mes dossiers" : "Mes projets"}
      header={
        <Search
          setSearch={setSearch}
          placeholder={
            isManager
              ? "Bénéficiaire ou référence…"
              : "Recherche opération ou chantier…"
          }
        />
      }
    >
      <DossierList setFilters={setFilters} />
    </Main>
  );
}

Dossiers.defaultProps = {
  archive: false,
};

Dossiers.propTypes = {
  archive: PropTypes.bool,
};
