import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import { useAuth } from "../AuthProvider";
// import { fetchApi } from "../../fetchApi";
import { mutate } from "swr";
import axios from "axios";
import { useApiEndpoint } from "../../ApiEndpointContext";
import { ReactComponent as LoadingIcon } from "../../images/icons/loading.svg";
function Champ({ label, value }) {
  return (
    <div className="border-b p-2">
      <p className="text-xs text-[#212121]">{label}</p>
      <p>{value || "Aucun"}</p>
    </div>
  );
}

export default function AhDialog({ onClose, id, fetchFilesData, data }) {
  const apiEndpoint = useApiEndpoint();
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  //   const [resultID, setResultID] = useState(1);
  const auth = useAuth();
  const [formData, setFormData] = useState({});

  const onSubmitStep = async (data) => {
    // Store data from the current step
    setFormData({ ...formData, ...data });

    try {
      setLoading(true);

      const api_result = await axios.get(
        `${apiEndpoint}/api/generationah/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      );
      // Check if both API calls were successful
      if (api_result.data) {
        // Trigger a revalidation (refetch)
        mutate(
          `/api/documents?filters[dossier][id][$eq]=${id}&pagination[limit]=100`,
        );
        console.log(api_result);
        setSuccess(true);
        onClose();
      } else {
        // Handle the case where one or both API calls failed
        console.log("api_result generation AH", api_result);
        setError("One or more API calls failed.");
      }
    } catch (error) {
      setError(error.message);
      console.log("error", error);
    } finally {
      setLoading(false);
      fetchFilesData();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center overflow-scroll">
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center h-[1050px]`}
      ></div>
      <form
        onSubmit={handleSubmit(onSubmitStep)}
        className={`w-[80%] max-w-[689px] mt-20 p-8 grid- bg-white rounded border relative gap-3  h-[900px]`}
      >
        <button
          type="button"
          onClick={onClose}
          className="text-xs underline absolute right-4 top-4 z-20"
        >
          Fermer
        </button>
        <div className="">
          <h2 className="text-xl font-semibold text-main-color mb-3">
            Est-ce que les informations ci-dessous sont correctes ?
          </h2>
        </div>

        <div className="col-span-2 flex flex-col gap-1">
          <div className="bg-main-color text-white px-4 py-3 rounded mb-2 font-semibold flex justify-between">
            <span>Attestation sur l’honneur</span>
          </div>

          <Champ
            label="Date de signature du devis"
            value={
              data.data.attributes.chantiers.data[0].attributes
                .date_signature_devis
            }
          />
          <Champ
            label="Date d’édition de la facture"
            value={
              data.data.attributes.chantiers.data[0].attributes
                .date_edition_facture
            }
          />
          <Champ
            label="Nom"
            value={data.data.attributes.beneficiaire.data.attributes.nom}
          />
          <Champ
            label="Prénom"
            value={data.data.attributes.beneficiaire.data.attributes.prenom}
          />
          <Champ
            label="Adresse du chantier ou Parcelle cadastrale"
            value={data.data.attributes.chantiers.data[0].attributes.adresse}
          />
          <Champ
            label="Code postal du chantier"
            value={
              data.data.attributes.chantiers.data[0].attributes.code_postal
            }
          />
          <Champ
            label="Ville du chantier"
            value={data.data.attributes.chantiers.data[0].attributes.ville}
          />
          <Champ
            label="Bâtiment construit depuis plus de 2 ans"
            value={
              data.data.attributes.chantiers.data[0].attributes.plus_de_deux_ans
                ? "Oui"
                : "Non"
            }
          />
          <Champ
            label="Type de logement"
            value={
              data.data.attributes.chantiers.data[0].attributes.type_de_logement
            }
          />
          <Champ
            label="Information sur le professionnel"
            value={
              data.data.attributes.entreprise_de_travaux.data.attributes
                .raison_sociale
            }
          />
          <Champ
            label="FOST"
            value={data.data.attributes.fosts.data[0].attributes.numero_fost}
          />
        </div>

        <div className="col-span-2">
          <Button
            label={"Générer le document"}
            className="mx-auto mt-4"
            variant="contained"
            color={"blue"}
            type="submit"
            icon={loading ? <LoadingIcon /> : ""}
            disabled={error}
          />

          {error && (
            <p className="text-red-500 text-xs mx-auto mt-3">
              Un problème est survenu lors de l'envoi des documents, veuillez
              contacter votre administrateur. (Champs obligatoires : FOST et
              Date de signature du devis)
            </p>
          )}

          {success && (
            <p className="text-green text-xs mx-auto mt-3">succès !</p>
          )}
        </div>
      </form>
    </div>
  );
}
