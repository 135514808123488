import React, { useState } from "react";
import PropTypes from "prop-types";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import { useAuth } from "components/AuthProvider";
import { useConfig } from "providers/ConfigProvider";
import useFetchApi from "hooks/useFetchApi";

export default function FieldBlockDocument({
  name,
  value,
  id,
  fetchFilesData,
}) {
  const auth = useAuth();
  const { isCustomerUrl } = useConfig();
  const role = auth?.user?.role?.name;
  const isManager = !isCustomerUrl && role && role !== "Client";
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const cleanedValue = value.replace(".pdf", "");
  const [answer, setAnswer] = useState(cleanedValue); // avoid modify file type
  const onSubmit = async () => {
    setEditing(!editing);
    document.activeElement?.blur();
    const body = { [name]: answer + ".pdf" }; // add back file type for API
    // console.log('body', body)
    try {
      await fetchApi(`document/modify-document/${id}`, {
        method: "PUT",
        token: auth.token,
        body,
      });
      // Trigger a revalidation (refetch)
      fetchFilesData();
      // onClose();
    } catch ({ message }) {
      // setError(message);
    } finally {
      setAnswer(cleanedValue);
      // console.log('value', value)
    }
  };
  const onCancel = () => {
    setEditing(!editing);
    setAnswer(cleanedValue);
  };
  return (
    <div className="flex flex-row items-center justify-between pb-1 mt-1 pt-1 w-full">
      <div className="flex-1 flex flex-col w-full">
        {!editing && <p className="truncate">{value || "-"}</p>}
        {editing && (
          <input
            type="text"
            value={answer || ""}
            className="w-full"
            onChange={(e) => setAnswer(e.target.value)}
            autoFocus
          />
        )}
      </div>
      <div className="flex-0">
        {editing && role !== "Consultation" && (
          <div className="flex space-x-1">
            <button onClick={onSubmit}>
              <img src={iconConfirm} alt="confirmer" className="max-w-none" />
            </button>
            <button onClick={onCancel}>
              <img src={iconCancel} alt="annuler" className="max-w-none" />
            </button>
          </div>
        )}

        {!editing && isManager && role !== "Consultation" && (
          <button onClick={() => setEditing(!editing)} className="ml-2">
            <img src={iconEdit} alt="modifier" className="max-w-none" />
          </button>
        )}
      </div>
    </div>
  );
}
FieldBlockDocument.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fetchFilesData: PropTypes.func.isRequired,
};
