import { ReactComponent as IdIcon } from "images/icons/selectIcon-id.svg";
import { ReactComponent as EuroIcon } from "images/icons/selectIcon-euro.svg";
import { ReactComponent as DocIcon } from "images/icons/selectIcon-doc.svg";
import { ReactComponent as DocsIcon } from "images/icons/selectIcon-docs.svg";
import { ReactComponent as DocGraphIcon } from "images/icons/selectIcon-doc-graph.svg";
import { ReactComponent as DocTextIcon } from "images/icons/selectIcon-doc-text.svg";
import { ReactComponent as DocValideIcon } from "images/icons/selectIcon-doc-valide.svg";
import { ReactComponent as RibIcon } from "images/icons/selectIcon-rib.svg";

const hasFostCSTBat = (dossier) =>
  dossier.attributes?.fosts?.data?.find(({ attributes: fost }) =>
    fost.numero_fost.includes("BAR-TH-143"),
  );

const hasFostNoteDimensionnement = (dossier) =>
  dossier.attributes?.fosts?.data?.find(
    ({ attributes: fost }) =>
      fost.numero_fost.includes("BAR-TH-171") ||
      fost.numero_fost.includes("BAR-TH-172") ||
      fost.numero_fost.includes("BAR-TH-113") ||
      fost.numero_fost.includes("BAR-TH-159"),
  );

export const typesDocumentsGestionnaire = [
  { label: "Carte d'identité", Icon: IdIcon },
  { label: "FOST", Icon: DocsIcon },
  { label: "Devis", Icon: EuroIcon },
  { label: "Lettre d'engagement", Icon: DocTextIcon },
  { label: "Cadre de contribution", Icon: DocIcon },
  { label: "Document technique", Icon: DocGraphIcon },
  { label: "Attestation sur l'honneur", Icon: DocValideIcon },
  { label: "Avis d'imposition", Icon: DocTextIcon },
  { label: "Facture", Icon: EuroIcon },
  { label: "RIB", Icon: RibIcon },
  { label: "Note de dimensionnement", Icon: DocIcon },
  { label: "Certif RGE", Icon: DocValideIcon },
  { label: "Certification CSTBat ou Solar Keymark", Icon: DocGraphIcon },
  { label: "Autre document", Icon: DocsIcon },
];

export const typesDocumentsClient = [
  { label: "Avis d'imposition", Icon: DocTextIcon },
  { label: "Devis avant signature", Icon: EuroIcon },
  { label: "Devis signé", Icon: EuroIcon },
  { label: "Attestation sur l'honneur signée", Icon: DocValideIcon },
  { label: "Certificat RGE", Icon: DocValideIcon },
  { label: "Facture", Icon: EuroIcon },
  { label: "IBAN", Icon: RibIcon },
  {
    label: "Note de dimensionnement",
    Icon: DocIcon,
    isVisible: hasFostNoteDimensionnement,
  },
  {
    label: "Certification CSTBat ou Solar Keymark",
    Icon: DocGraphIcon,
    isVisible: hasFostCSTBat,
  },
  { label: "Autre", Icon: DocsIcon },
];

export const typesDocumentsClientToGestionnaire = {
  "Avis d'imposition": "Avis d'imposition",
  "Devis avant signature": "Devis",
  "Devis signé": "Devis",
  "Attestation sur l'honneur signée": "Attestation sur l'honneur",
  "Certificat RGE": "Certif RGE",
  Facture: "Facture",
  IBAN: "RIB",
  "Note de dimensionnement": "Note de dimensionnement",
  Autre: "Autre document",
  "Certification CSTBat ou Solar Keymark":
    "Certification CSTBat ou Solar Keymark",
};

export const typesDocumentsClientsStatuts = {
  "Nouvelle demande": [
    "Avis d'imposition",
    "Devis avant signature",
    "Certification CSTBat ou Solar Keymark",
    "Autre",
  ],
  "Travaux en cours": [
    "Devis signé",
    "Attestation sur l'honneur signée",
    "Certificat RGE",
    "Facture",
    "IBAN",
    "Note de dimensionnement",
  ],
};
