import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Tab from "components/Tab";
import TabForm from "components/TabForm";
import iconID from "images/icons/id.svg";
import iconDoc from "images/icons/doc.svg";
import iconEuro from "images/icons/euro-blue.svg";
import Button from "components/Button";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import useFetchApi from "hooks/useFetchApi";

export default function Beneficiaire({ id, data, fetchData, role }) {
  const [fetchApi] = useFetchApi();
  const [loading, setLoading] = useState(false);
  const [primeError, setPrimeError] = useState("");

  const dossier = data?.data?.attributes;
  const beneficiaire = dossier?.beneficiaire?.data?.attributes;
  const beneficiaireId = dossier?.beneficiaire?.data?.id;
  const gestionnaire = dossier?.gestionnaire?.data?.attributes;
  const client = dossier?.client?.data?.attributes;

  const tabData = [
    {
      title: "Informations bénéficiaire",
      icon: iconID,
      fields: [
        {
          name: "civilite",
          label: "Civilité",
          value: beneficiaire?.civilite,
          sectionID: beneficiaireId,
          route: "contact",
          type: "select",
          options: ["Monsieur", "Madame"],
        },
        {
          name: "nom",
          label: "Nom",
          value: beneficiaire?.nom,
          sectionID: beneficiaireId,
          route: "contact",
          require: true,
          maxLength: 255,
        },
        {
          name: "prenom",
          label: "Prenom",
          value: beneficiaire?.prenom,
          sectionID: beneficiaireId,
          route: "contact",
          require: true,
          maxLength: 255,
        },
        {
          name: "telephone",
          label: "Téléphone",
          value: beneficiaire?.telephone,
          sectionID: beneficiaireId,
          route: "contact",
          nospace: true,
          type: "tel",
          maxLength: 10,
        },
        {
          name: "email",
          label: "Adresse mail",
          value: beneficiaire?.email,
          sectionID: beneficiaireId,
          route: "contact",
          nospace: true,
          type: "email",
          maxLength: 255,
        },
        {
          name: "adresse",
          label: "Adresse",
          value: beneficiaire?.adresse,
          sectionID: beneficiaireId,
          route: "contact",
          require: true,
          maxLength: 255,
        },
        {
          name: "code_postal",
          label: "Code postal",
          value: beneficiaire?.code_postal,
          sectionID: beneficiaireId,
          route: "contact",
          require: true,
          nospace: true,
          type: "number",
          minLength: 5,
          maxLength: 5,
        },
        {
          name: "ville",
          label: "Ville",
          value: beneficiaire?.ville,
          sectionID: beneficiaireId,
          route: "contact",
          require: true,
          maxLength: 255,
        },
        {
          name: "pays",
          label: "Pays",
          value: beneficiaire?.pays,
          sectionID: beneficiaireId,
          route: "contact",
          maxLength: 255,
        },
        {
          name: "IBAN",
          label: "IBAN",
          value: beneficiaire?.IBAN,
          sectionID: beneficiaireId,
          route: "contact",
          nospace: true,
          minLength: 27,
          maxLength: 27,
        },
        {
          name: "BIC",
          label: "BIC",
          value: beneficiaire?.BIC,
          sectionID: beneficiaireId,
          route: "contact",
          nospace: true,
          minLength: 8,
          maxLength: 11,
        },
        // {
        //   name: "Type_de_versement_de_la_prime",
        //   label: "Type de versement de la prime",
        //   value: beneficiaire?.Type_de_versement_de_la_prime,
        //   sectionID: beneficiaireId,
        //   route: "contact",
        //   type: "select",
        //   options: ["Virement", "Lettre chèque"],
        // },
        {
          name: "gestionnaire",
          label: "Gestionnaire du dossier",
          value: gestionnaire?.username,
          type: "readonly",
        },
        {
          name: "client",
          label: "Espace bénéficiaire",
          value: client ? "Oui" : "Non",
          type: "readonly",
        },
      ],
    },
    {
      title: "Contrôle RFR",
      icon: iconDoc,
      fields: [
        {
          name: "numero_teledeclarant",
          label: "Numéros télédéclarant",
          value: dossier?.numero_teledeclarant,
          maxLength: 255,
        },
        {
          name: "reference_avis_imposition",
          label: "Référence de l’avis d’imposition",
          value: dossier?.reference_avis_imposition,
          maxLength: 255,
        },
        {
          name: "revenu_fiscal_de_reference",
          label: "Revenu fiscal de référence",
          value: dossier?.revenu_fiscal_de_reference,
          maxLength: 255,
        },
        {
          name: "statut_precarite",
          label: "Statut Précarité",
          value: dossier?.statut_precarite,
          type: "select",
          options: ["Classique", "Modeste", "Précaire"],
        },
        {
          name: "nombre_de_personnes_dans_le_menage",
          label: "Nombre de personnes composant le ménage",
          value: dossier?.nombre_de_personnes_dans_le_menage,
          type: "number",
          maxLength: 4,
        },
        {
          name: "situation_demandeur",
          label: "Situation Demandeur",
          value: dossier?.situation_demandeur,
          type: "select",
          options: [
            "Propriétaire occupant",
            "Propriétaire non-occupant",
            "Propriétaire bailleur",
            "Locataire",
          ],
        },
        {
          name: "avis_imposition_locataire",
          label: "Avis d'imposition du locataire",
          value: dossier?.avis_imposition_locataire,
          type: "checkbox",
          displayNone:
            dossier?.situation_demandeur !== "Propriétaire non-occupant" &&
            dossier?.situation_demandeur !== "Propriétaire bailleur",
        },
        {
          name: "couleur_du_menage",
          label: "Couleur du menage",
          value: dossier?.couleur_du_menage,
          type: "select",
          options: ["Bleu", "Jaune", "Violet", "Rose"],
        },
      ],
    },
    {
      title: "Montants",
      icon: iconEuro,
      fields: [
        {
          name: "Volume_kWWhc",
          label: "Volumes (kWhc)",
          value: dossier?.Volume_kWWhc,
          type: "number",
          maxLength: 8,
        },
        {
          name: "Prime_CEE",
          label: "Prime CEE",
          value: dossier?.Prime_CEE,
          type: "decimalnumber",
          maxLength: 10,
          decimals: 2,
        },
        // { name: "Prime_MPR", label: "Prime MPR", value: dossier?.Prime_MPR },
        {
          name: "Montant_des_travaux",
          label: "Montant des travaux",
          value: dossier?.Montant_des_travaux,
          type: "decimalnumber",
          maxLength: 10,
          decimals: 2,
        },
        // { name: "Reste_a_payer", label: "Reste à payer", value: dossier?.Reste_a_payer },
      ],
    },
  ];

  const handleGeneratePrime = useCallback(async () => {
    try {
      setLoading(true);
      await fetchApi(`calculate-volume-and-prime-cee/${id}`, {
        method: "POST",
      });
      fetchData();
      setPrimeError(null);
    } catch (error) {
      console.warn("ERROR", error);
      if (error?.data) {
        setPrimeError(
          `Le calcul de la prime CEE necessite les informations suivantes : ${error.data?.join(", ")}`,
        );
      } else {
        setPrimeError("Impossible de calculer le volume et la prime CEE.");
      }
    } finally {
      setLoading(false);
    }
  }, [id, fetchApi, fetchData]);

  if (!data) {
    return (
      <div className="mt-4 w-[727px]">
        <LoadingIcon />
      </div>
    );
  } else {
    return (
      <div className="mt-4 w-[727px]">
        <div>
          {tabData.map((tab, tabIndex) => (
            <Tab title={tab.title} icon={tab.icon} key={`tab_${tabIndex}`}>
              <TabForm
                tab={tab}
                id={id}
                error={tab.title === "Montants" ? primeError : null}
                role={role}
                fetchData={fetchData}
              ></TabForm>
            </Tab>
          ))}
        </div>
        {role !== "Consultation" && (
          <Button
            label="Générer volume et prime"
            className="mt-3"
            variant="contained"
            color="blue"
            type="submit"
            icon={loading ? <LoadingIcon /> : ""}
            onClick={() => handleGeneratePrime()}
          />
        )}
      </div>
    );
  }
}
Beneficiaire.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  fetchData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};
