import React from "react";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "components/AuthProvider";
import { Link } from "react-router-dom";
import DossierList from "components/DossierList";

export default function LastViewFiles() {
  const { isCustomerUrl } = useConfig();
  const { role } = useAuth();
  const isManager = !isCustomerUrl && role && role !== "Client";
  return (
    <div className="w-full pr-3">
      <div className="flex flex-row mb-5">
        <h3 className="font-semibold text-2xl text-main-color">
          {isManager
            ? "Derniers dossiers consultés"
            : "Mes derniers projets consultés"}
        </h3>
        <div className="flex-1"></div>
        <div className="flex items-center max-w-[60px] w-full">
          <Link to={isManager ? "/dossiers" : "/mes-projets"}>
            <button className="text-sm text-main-color underline">
              Voir tout
            </button>
          </Link>
        </div>
      </div>

      <DossierList
        lastView
        headers={false}
        pagination={false}
        pageSize={5}
        flex={false}
      />
    </div>
  );
}
