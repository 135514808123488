import React, { useState, useEffect } from "react";
import iconEdit from "../images/icons/edit.svg";
import iconCancel from "../images/icons/cancel.svg";
import iconConfirm from "../images/icons/confirm.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "./AuthProvider";
import { fetchApi } from "../fetchApi";
import { mutate } from "swr";

export default function FieldBlockDate({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll,
  updateGroupAnswers,
  role,
  fetchData,
}) {
  const [editing, setEditing] = useState(false);
  // Parse the initial value string to a Date object
  const parsedValue = value
    ? new Date(
        parseInt(value.substring(0, 4), 10),
        parseInt(value.substring(5, 7), 10) - 1, // Months are 0-indexed
        parseInt(value.substring(8, 10), 10),
      )
    : new Date();
  const [answer, setAnswer] = useState(parsedValue);
  const [changedAnswer, setChangedAnswer] = useState(!!value);
  // const options = {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: 'numeric'
  //   };

  // const formattedDate = answer.toLocaleString('fr-FR', options);
  // console.log(formattedDate)
  const auth = useAuth();
  const onSubmit = async () => {
    setEditing(!editing);
    document.activeElement?.blur();
    const adjustedAnswer = new Date(answer.setHours(answer.getHours() + 8));
    const body = route
      ? { [route]: { [name]: adjustedAnswer, id: sectionID } }
      : { [name]: adjustedAnswer };
    // console.log('body date', body,)
    try {
      await fetchApi(
        `/api/update-dossier/${id}`,
        { token: auth.token, body },
        "PUT",
      );
      // Trigger a revalidation (refetch) of the "Dossiers details" list.
      mutate(`/api/dossiers/${id}?populate=*`);
      // onClose();
    } catch ({ message }) {
      // setError(message);
    } finally {
      setAnswer(value ? value.substring(0, 10) : "");
      fetchData();
    }
  };
  const onCancel = () => {
    setEditing(!editing);
    setAnswer(parsedValue);
  };

  const handleAnswer = (item) => {
    setChangedAnswer(true);
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
    // console.log('set answer', item)
  };

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  if (editAll) {
    return (
      <div className="flex items-center justify-between border-b-2 pb-1 mt-1 pt-1">
        <div className="flex flex-col">
          <label className="text-xs">{label}</label>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            value={changedAnswer ? answer : "Sélectionner une date"}
            selected={new Date(answer)}
            onChange={(date) => handleAnswer(date)}
          />
        </div>
        <div>
          {/* {editing &&
                        <div className="flex space-x-1">
                            <button onClick={onSubmit}><img src={iconConfirm} alt="confirmer" /></button>
                            <button onClick={onCancel}><img src={iconCancel} alt="annuler" /></button>
                        </div>
                    }
    
                    {!editing &&
                        <button onClick={() => setEditing(!editing)}><img src={iconEdit} alt="modifier" /></button>
                    } */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-between border-b-2 pb-1 mt-1 pt-1">
        <div className="flex flex-col">
          <label className="text-xs">{label}</label>
          {!editing && (
            <p>{value ? value.substring(0, 10) : "Sélectionner une date"}</p>
          )}

          {editing && (
            <DatePicker
              dateFormat="yyyy-MM-dd"
              value={answer}
              selected={new Date(answer)}
              onChange={(date) => setAnswer(date)}
              autoFocus
            />
          )}
        </div>
        <div>
          {editing && role !== "Consultation" && (
            <div className="flex space-x-1">
              <button onClick={onSubmit}>
                <img src={iconConfirm} alt="confirmer" />
              </button>
              <button onClick={onCancel}>
                <img src={iconCancel} alt="annuler" />
              </button>
            </div>
          )}

          {!editing && role !== "Consultation" && (
            <button
              onClick={() => {
                setEditing(!editing);
                setAnswer(parsedValue);
              }}
            >
              <img src={iconEdit} alt="modifier" />
            </button>
          )}
        </div>
      </div>
    );
  }
}
