import React from "react";
import PropTypes from "prop-types";
import { useConfig } from "providers/ConfigProvider";
import { Link, useLocation } from "react-router-dom";
import logo from "images/logo_soregies.svg";
import beneficiareLogo from "images/beneficiareLogo.svg";
import { ReactComponent as AccountIcon } from "../images/icons/account.svg";
import { ReactComponent as AideIcon } from "../images/icons/aide.svg";
import { ReactComponent as FileIcon } from "../images/icons/file.svg";
import { ReactComponent as HomeIcon } from "../images/icons/home.svg";
import { ReactComponent as LogoutIcon } from "../images/icons/logout.svg";
import { ReactComponent as ClientIcon } from "../images/icons/client.svg";
// import { ReactComponent as NewsIcon } from "../images/icons/news.svg";
import { ReactComponent as SettingsIcon } from "../images/icons/settings.svg";
import { ReactComponent as DepotIcon } from "../images/icons/depot.svg";
import { ReactComponent as EuroIcon } from "../images/icons/prime.svg";
import { ReactComponent as IndicateurIcon } from "../images/icons/indicateur.svg";
import { ReactComponent as ReportingIcon } from "../images/icons/reporting.svg";
import { ReactComponent as SimulationIcon } from "../images/icons/simulation.svg";
import { ReactComponent as RgeIcon } from "../images/icons/rge.svg";

function MenuItem({ to, logo, label, external = false }) {
  const Logo = logo;
  const location = useLocation();
  const active =
    to === "/" ? location.pathname === "/" : location.pathname.startsWith(to);
  const extraProps = external ? { target: "_blank", rel: "noreferrer" } : {};
  return (
    <Link
      to={to}
      className={`flex items-center w-full ${
        active ? "bg-main-color text-white" : "text-main-color"
      } gap-4 h-12 rounded-lg pl-2 text-sm `}
      {...extraProps}
    >
      <Logo />
      <span className="font-medium">{label}</span>
    </Link>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  external: PropTypes.bool,
};

export default function Menu({ className, role }) {
  const { isCustomerUrl } = useConfig();
  const isManager = !isCustomerUrl && role && role !== "Client";
  const menuLogo = isManager ? logo : beneficiareLogo;
  return (
    <div
      className={`bg-light-color py-10 flex flex-col pl-4 pr-2 items-center ${className}`}
      style={{ zIndex: "9999999" }}
    >
      <img src={menuLogo} className={isManager ? "w-40" : "w-60"} alt="logo" />
      <h1 className="mb-10 mt-3 font-semibold text-sm text-main-color">
        {isManager ? <>Espace gestionnaire CEE</> : ""}
      </h1>
      <MenuItem to="/" logo={HomeIcon} label="Accueil" active />
      {isManager && (
        <MenuItem
          to="/simulations-clients"
          logo={ClientIcon}
          label="Simulations Clients"
        />
      )}
      {isManager && (
        <MenuItem to="/dossiers" logo={FileIcon} label="Mes dossiers" />
      )}
      {isManager && (
        <MenuItem to="/reporting" logo={ReportingIcon} label="Reporting" />
      )}

      {isManager && (
        <MenuItem to="/indicateurs" logo={IndicateurIcon} label="Indicateurs" />
      )}

      {isCustomerUrl && (
        <MenuItem
          to="/mes-simulations"
          logo={SimulationIcon}
          label="Mes simulations"
        />
      )}

      {isCustomerUrl && (
        <MenuItem to="/mes-projets" logo={FileIcon} label="Mes projets" />
      )}

      {isManager && role !== "Consultation" && (
        <MenuItem to="/depot-emmy" logo={DepotIcon} label="Dépôt Emmy" />
      )}
      {isManager && role !== "Consultation" && (
        <MenuItem
          to="/verser-une-prime"
          logo={EuroIcon}
          label="Verser une prime"
        />
      )}

      {/* <MenuItem to="/actualite" logo={NewsIcon} label="Actualité" /> */}
      <div className="flex-1"></div>

      <MenuItem to="/compte" logo={AccountIcon} label="Mon compte" />
      {isManager && role === "Administrateur" && (
        <MenuItem to="/parametres/" logo={SettingsIcon} label="Paramètres" />
      )}

      {!isManager && (
        <MenuItem
          external
          to="https://www.soregies.fr/concretiser-vos-travaux-partenaires-rge/"
          logo={RgeIcon}
          label="Nos partenaires RGE"
        />
      )}
      {!isManager && (
        <MenuItem
          external
          to="https://www.soregies.fr/faq/"
          logo={AideIcon}
          label="Aide"
        />
      )}
      <div className="h-5"></div>
      <MenuItem to="/deconnexion" logo={LogoutIcon} label="Se déconnecter" />
    </div>
  );
}

Menu.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
};

Menu.defaultProps = {
  className: "",
  role: null,
};
