import { React, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Notification from "images/icons/notification.svg";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "components/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import { ReactComponent as DocumentIcon } from "images/icons/notification-document.svg";
import { ReactComponent as DocumentCheckIcon } from "images/icons/notification-document-check.svg";
import { ReactComponent as LetterIcon } from "images/icons/notification-letter.svg";
import { ReactComponent as FolderIcon } from "images/icons/notification-folder.svg";
import { timeAgo } from "functions";

const MAX_PER_PAGE = 10;

const REFRESH_INTERVAL = 60; // en secondes

function getNotificationsUrl(read) {
  return `notifications?filters[is_read]=${read ? "true" : "false"}&populate=*&pagination[limit]=${MAX_PER_PAGE}&sort[0]=createdAt:desc`;
}
const unreadUrl = getNotificationsUrl(false);
const readUrl = getNotificationsUrl(true);

function getIcon(type) {
  switch (type) {
    case "document":
      return <DocumentIcon className="h-4 max-w-3.5" />;
    case "message":
    case "received":
      return <LetterIcon className="h-4 max-w-3.5" />;
    case "conversion":
      return <DocumentCheckIcon className="h-4 max-w-3.5" />;
    case "validated":
    default:
      return <FolderIcon className="h-4 max-w-3.5" />;
  }
}

function getTitle(isManager, type, dossier, count) {
  if (isManager) {
    return dossier.data.attributes.reference;
  }
  switch (type) {
    case "document":
      return count > 1
        ? "Nouveaux documents disponibles"
        : "Nouveau document disponible";
    case "message":
      return count > 1
        ? "Nouveaux messages disponibles"
        : "Vous avez un nouveau message";
    case "received":
      return "Documents reçus";
    case "conversion":
      return "Transformation de votre simulation en projet réussie";
    case "validated":
      return "Dossier complet et conforme";
    default:
      return dossier.data.attributes.reference;
  }
}

function getDescription(isManager, type, user, count) {
  if (isManager) {
    switch (type) {
      case "document":
        return count > 1
          ? "Nouveaux documents disponibles"
          : "Nouveau document disponible";
      case "message":
        return count > 1
          ? "Nouveaux messages disponibles"
          : "Nouveau message disponible";
      case "received":
        return "Documents reçus et à traiter";
      case "conversion":
        return "Simulation convertie en dossier";
      case "validated":
        return "Dossier validé";
      default:
        return "";
    }
  }
  switch (type) {
    case "document":
    case "message":
      return "Veuillez vous rendre dans votre messagerie afin d'en prendre connaissance.";
    case "received":
      return "L'éligibilité de votre projet est désormais en cours d'analyse.";
    case "conversion":
      return "Si ce n'est pas le cas, veuillez joindre les documents nécessaires à l'avancement de votre projet.";
    case "validated":
      return `Félicitations ! Vous serez informé${user.title === "Mme" ? "e" : ""} par email dès que le virement de votre prime sera effectué.`;
    default:
      return "";
  }
}

function getUrl(isManager, type, dossierId) {
  const dossierUrl = `${isManager ? "mes-projets" : "dossiers"}/${dossierId}`;
  switch (type) {
    case "conversion":
    case "document":
      return `${dossierUrl}/documents`;
    case "message":
      return `${dossierUrl}/messagerie`;
    case "received":
    case "validated":
    default:
      return dossierUrl;
  }
}

function NotificationUnit({ notification, updateNotification }) {
  const { isCustomerUrl } = useConfig();
  const { role, user } = useAuth();
  const isManager = !isCustomerUrl && role && role !== "Client";
  const handleClick = () => {
    updateNotification(notification.id);
  };
  const unread = !notification.attributes.is_read;
  const dossierId = notification.attributes.dossier.data.id;
  const type = notification.attributes.type;
  const date = timeAgo(notification.attributes.updatedAt);
  const url = getUrl(isManager, type, dossierId);
  const count = notification.attributes.count ?? 1;

  return (
    <div
      key={notification.id}
      className="my-3 border-b pb-3"
      onClick={handleClick}
    >
      <div className={`flex ${unread ? "" : "opacity-75"}`}>
        <div className="w-[300px]">
          <Link reloadDocument to={url}>
            <h2
              className={`${unread ? "text-main-color" : "text-gray-500"} text-xs underline flex items-center`}
            >
              <span className="mr-1">{getIcon(type)}</span>
              {getTitle(
                isManager,
                type,
                notification.attributes.dossier,
                count,
              )}
            </h2>
            <p className="text-xxs text-gray-500">
              {getDescription(isManager, type, user, count)}
            </p>
          </Link>
        </div>
        <div className="text-right w-[120px]">
          <span className="text-xxs text-gray-500">{date}</span>
        </div>
      </div>
    </div>
  );
}
NotificationUnit.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
  updateNotification: PropTypes.func.isRequired,
};

export default function NotificationButton() {
  const [fetchApi] = useFetchApi();
  const [unread, setUnread] = useState(true);
  const [open, setOpen] = useState(false);
  const notificationRef = useRef(null);
  const notificationTimer = useRef(null);
  const { data: unreadData } = useSWR(unreadUrl, fetchApi);
  const { data: readData } = useSWR(readUrl, fetchApi);
  const unreadCount = unreadData?.meta?.pagination?.total;
  const unreadMessages = unreadData?.data;
  const readMessages = readData?.data;

  const handleOutsideClick = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Fetch les nouvelles notifications à intervalle régulier
  useEffect(() => {
    clearTimeout(notificationTimer.current);
    notificationTimer.current = setInterval(() => {
      mutate(unreadUrl);
      mutate(readUrl);
    }, REFRESH_INTERVAL * 1000);
    return () => clearInterval(notificationTimer.current);
  }, []);

  const updateNotification = async (id) => {
    const body = { notificationIds: [id] };
    try {
      await fetchApi("open-notifications", {
        body,
        method: "PUT",
      });
      mutate(unreadUrl);
      mutate(readUrl);
    } catch ({ message }) {
      console.warn("Error updating notifications:", message);
    }
  };

  const notificationUnitsUnread = unreadMessages?.map((notification) => (
    <NotificationUnit
      key={notification.id}
      notification={notification}
      updateNotification={updateNotification}
    />
  ));

  const notificationUnitsRead = readMessages?.map((notification) => (
    <NotificationUnit
      key={notification.id}
      notification={notification}
      updateNotification={updateNotification}
    />
  ));

  const zeroNotif = unreadMessages?.length === 0 || !unreadMessages?.length;

  return (
    <div>
      <div className="w-[50px] h-[40px] pt-0.5 flex align-center justify-center cursor-pointer relative">
        <button onClick={() => setOpen(!open)}>
          {unreadCount ? (
            <span
              className={`${zeroNotif ? "hidden " : ""} bg-[#DF4D4D] absolute text-xxs text-white px-1 left-8 rounded-sm -mt-1`}
            >
              {unreadCount}
            </span>
          ) : (
            ""
          )}
          <img src={Notification} alt="notifications" />
        </button>
      </div>
      {open && (
        <div
          className="absolute z-[10] top-[93px] bg-white rounded-xl border-0.5 border-stone-400 right-3 w-[370px] min-h-[200px] p-4"
          ref={notificationRef}
        >
          <button
            className="text-xs absolute right-4 underline cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Fermer
          </button>
          <h1 className="text-main-color font-semibold text-xl align-top">
            Notifications
          </h1>

          <div className="flex gap-3 my-5">
            <button
              className={`text-l align-top ${unread ? "font-semibold text-main-color" : "font-normal text-slate-400"}`}
              onClick={() => setUnread(true)}
            >
              <span
                className={`pb-1 ${unread ? "border-b-2 border-blue" : ""}`}
              >
                Non lues
              </span>
              {
                <span
                  className={`${zeroNotif ? "bg-[#6FC88E]" : "bg-[#F77169]"} inline-block align-middle text-xs text-white px-2 mx-1 rounded`}
                >
                  {unreadCount}
                </span>
              }
            </button>
            <button
              className={`text-l align-top ${!unread ? "font-semibold text-main-color" : "font-normal text-slate-400"}`}
              onClick={() => setUnread(false)}
            >
              <span
                className={`pb-1 ${!unread ? "border-b-2 border-blue" : ""}`}
              >
                Lues
              </span>
            </button>
          </div>

          {!unread && notificationUnitsRead}
          {unread && notificationUnitsUnread}
          {unreadMessages?.length === 0 && unread && (
            <div className="w-full h-[200px] flex justify-center items-center text-sm text-light-color">
              Vous n&apos;avez pas de nouvelle notification.
            </div>
          )}
          {readMessages?.length === 0 && !unread && (
            <div className="w-full h-[200px] flex justify-center items-center text-sm text-light-color">
              Vous n&apos;avez pas de notification.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
