import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Main from "components/Main";

export default function PageMarkdown({ filename, title }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(filename)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [filename]);

  return (
    <Main
      title={
        <>
          <Link to="/compte">
            <svg
              className="inline-block align-middle -mt-1 mr-3"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 17L2 9.5M2 9.5L9.5 2M2 9.5L20 9.5"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          {title}
        </>
      }
    >
      <ReactMarkdown className="markdown">{content}</ReactMarkdown>
    </Main>
  );
}
PageMarkdown.propTypes = {
  filename: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
