import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import { useAuth } from "../AuthProvider";
import { fetchApi } from "../../fetchApi2";
import { mutate } from "swr";

// import { Navigate } from "react-router-dom";

export default function CreateUserDialog({ onClose }) {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [body, setBody] = useState("");
  const [tokenAPI, setTokenAPI] = useState("");

  const auth = useAuth();
  const onSubmit = async (data) => {
    // We blur the active form so that the user can't submit it twice
    // by pressing the enter key, when showing the overlay.
    document.activeElement?.blur();
    try {
      setError("");
      setSuccess(false);
      // console.log('datafromUser', data)
      setBody(data);
      setLoading(true);
      const res = await fetchApi("/api/create-user", {
        token: auth.token,
        body: data,
      });
      console.log("res", res.data);
      mutate("/api/users?populate=role&pagination[pageSize]=100");
      setSuccess(true);
      setTokenAPI(res.data);
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  function Resend({ body }) {
    // const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const resendEmail = async () => {
      console.log("resend....", body);
      document.activeElement?.blur();
      try {
        setError("");
        setSuccess(false);
        setLoading(true);
        // await fetchApi("/api/re-send-email", body);
        // console.log(body.token)
        await fetchApi("/api/re-send-email", { token: auth.token, body });
        setSuccess(true);
      } catch ({ message }) {
        setError(message);
      } finally {
        setLoading(false);
      }
    };

    return (
      <div>
        <button onClick={resendEmail} className="mt-1 underline">
          Renvoyer un lien de connexion
        </button>
        <div className="mt-1">
          {loading && <p className="text-light-color">Envoi en cours...</p>}
          {success && <p className="text-green">Success! </p>}
          {error && <p>{error}</p>}
        </div>
      </div>
    );
  }
  function Input({ label, name, register, className, type = "text" }) {
    return (
      <div className={`flex flex-col w-[450px] mt-3 gap-2 ${className ?? ""}`}>
        <label className="text-main-color text-sm" htmlFor={name}>
          {label} {name !== "telephone" && "*"}
        </label>
        <input
          type={type}
          {...register(name)}
          id={name}
          className="w-full border rounded h-10 px-2 text-sm"
          placeholder={label}
          required={name !== "telephone"}
        />
      </div>
    );
  }

  return (
    <div className="w-[80%] max-w-[537px] p-8 bg-white rounded border relative flex items-center flex-col">
      <form onSubmit={handleSubmit(onSubmit)}>
        <button
          type="button"
          onClick={onClose}
          className="text-xs underline absolute right-4 top-4 z-20"
        >
          Fermer
        </button>
        <div className="w-full text-center">
          <h2 className="text-xl font-semibold text-main-color my-4">
            Ajoutez un nouvel utilisateur
          </h2>
        </div>

        <Input label="Prénom" name="firstname" register={register} />
        <Input label="Nom" name="name" register={register} />
        <Input
          label="Adresse mail"
          name="email"
          type="email"
          register={register}
        />
        <Input
          label="Numéro de téléphone"
          name="telephone"
          register={register}
        />
        <div className="col-span-2">
          <Button
            label="Créer l’utilisateur"
            className="mx-auto mt-7 mb-2"
            variant="contained"
            type="submit"
          />
        </div>
        {/* <span className="absolute left-3.5 bottom-3.5 text-xs text-light-color">
        *champs obligatoire
      </span> */}
      </form>
      {(loading || error || success) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
          {loading && (
            <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
          )}
          {error && (
            <div className="text-red-500 text-center text-lg font-semibold">
              Une erreur est survenue lors de la création du utilisateur!
            </div>
          )}
          {success && (
            <div className="items-center gap-6 text-center">
              <div className="text-main-color text-xl font-semibold">
                Un lien à bien été envoyé à <br />{" "}
                {body ? body.email : "votre adresse mail"}
              </div>
              <div className="text-xs mt-4">
                <p>Le lien n’a pas été reçu ? </p>

                <Resend
                  body={{
                    email: body.email,
                    token: tokenAPI,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
