import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";

export const ConfigContext = createContext();

export function useConfig() {
  return useContext(ConfigContext);
}

/**
 * Détecte la configuration pour le contexte
 * @returns {object}
 */
export function detectConfig() {
  // Chargement du fichier de configuration de l'instance
  if (!process.env.REACT_APP_CONFIG) {
    throw new Error(
      "Config file name is not defined in REACT_APP_CONFIG env variable.",
    );
  }
  const config = require(`config/${process.env.REACT_APP_CONFIG}.json`);

  // Paramètres de configuration à fournir dans le contexte
  const contextConfig = {
    environment: "default", // Nom de l'environnement utilisé
    apiUrl: "http://localhost:1337", // URL du back-end
    frontUrl: "http://localhost:3000", // URL du front
    simulatorUrl: "http://localhost:3001", // URL du simulateur intégré
    isCustomerUrl: null, // True lorsque l'application est accédée en tant qu'espace client
    isManagerUrl: null, // True lorsque l'application est accédée en tant qu'espace gestionnaire
  };

  // Détection de la configuration en fonction de l'URL
  for (const environment of Object.keys(config)) {
    const envConfig = config[environment];

    const customerUrls = Array.isArray(envConfig.customerUrl)
      ? [...envConfig.customerUrl]
      : [envConfig.customerUrl];
    const managerUrls = Array.isArray(envConfig.managerUrl)
      ? [...envConfig.managerUrl]
      : [envConfig.managerUrl];

    const customerUrl = customerUrls.find((url) =>
      window.location.href.includes(url),
    );
    const managerUrl = managerUrls.find((url) =>
      window.location.href.includes(url),
    );

    if (customerUrl || managerUrl) {
      contextConfig.environment = environment;
      contextConfig.apiUrl = envConfig.apiUrl;
      contextConfig.frontUrl = customerUrl || managerUrl;
      contextConfig.simulatorUrl = envConfig.simulatorUrl;
      contextConfig.isCustomerUrl = !!customerUrl;
      contextConfig.isManagerUrl = !!managerUrl;
    }
  }

  // Surcharge de la configuration à l'aide des variables d'environnement
  if (process.env.REACT_APP_API_ENDPOINT) {
    contextConfig.environment = "custom";
    contextConfig.apiUrl = process.env.REACT_APP_API_ENDPOINT;
  }
  if (
    process.env.REACT_APP_MANAGER_URL &&
    window.location.href.includes(process.env.REACT_APP_MANAGER_URL)
  ) {
    contextConfig.environment = "custom";
    contextConfig.frontUrl = process.env.REACT_APP_MANAGER_URL;
    contextConfig.isCustomerUrl = false;
    contextConfig.isManagerUrl = true;
  }
  if (
    process.env.REACT_APP_CUSTOMER_URL &&
    window.location.href.includes(process.env.REACT_APP_CUSTOMER_URL)
  ) {
    contextConfig.environment = "custom";
    contextConfig.frontUrl = process.env.REACT_APP_CUSTOMER_URL;
    contextConfig.isCustomerUrl = true;
    contextConfig.isManagerUrl = false;
  }
  if (process.env.REACT_APP_SIMULATOR_URL) {
    contextConfig.environment = "custom";
    contextConfig.simulatorUrl = process.env.REACT_APP_SIMULATOR_URL;
  }

  // Renvoie la configuration pour le contexte
  return contextConfig;
}

/**
 * Fournit un contexte avec la configuration de l'instance de l'application.
 */
export default function ConfigProvider({ children }) {
  const contextValue = useMemo(() => detectConfig(), []);
  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
