import React from "react";
import PropTypes from "prop-types";

const disabledColors = {
  bg: "bg-stone-300",
  text: "text-stone-300",
  border: "border-stone-300",
};

const colors = {
  blue: {
    bg: "bg-main-color",
    text: "text-main-color",
    border: "border-blue",
  },
  orange: {
    bg: "bg-[#FB7465]",
    text: "text-[#FB7465]",
    border: "border-[#FB7465]",
  },
  green: {
    bg: "bg-[#70C88E]",
    text: "text-[#70C88E]",
    border: "border-[#70C88E]",
  },
  red: {
    bg: "bg-[#DF4D4D]",
    text: "text-[#70C88E]",
    border: "border-[#DF4D4D]",
  },
  lightblue: {
    bg: "bg-lightblue",
    text: "text-main-color",
    border: "bg-lightblue",
  },
  disabled: {
    ...disabledColors,
  },
};

export default function Button({
  label,
  variant = "contained",
  color = "blue",
  icon,
  onClick,
  disabled,
  className,
  type = "button",
}) {
  const styleClasses = [
    "py-3 px-4 font-semibold rounded-md flex flex-row items-center gap-2",
    className,
  ];
  const activeColor = disabled ? disabledColors : colors[color] ?? colors.blue;

  if (variant === "contained") {
    styleClasses.push(activeColor.bg);
    styleClasses.push("text-white");
  } else if (variant === "outlined") {
    styleClasses.push("border");
    styleClasses.push(activeColor.border);
    styleClasses.push(activeColor.text);
  } else if (variant === "noborder") {
    styleClasses.push(activeColor.border);
    styleClasses.push(activeColor.text);
  }

  return (
    <button
      className={styleClasses.join(" ")}
      onClick={onClick}
      disabled={disabled ?? false}
      type={type}
    >
      {icon}
      {label}
    </button>
  );
}
Button.propTypes = {
  label: PropTypes.any,
  variant: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
};
