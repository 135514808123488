import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SendIcon } from "images/icons/send.svg";
import { ReactComponent as AttachIcon } from "images/icons/attach.svg";
import { useAuth } from "components/AuthProvider";
import { useConfig } from "providers/ConfigProvider";
import useFetchApi from "hooks/useFetchApi";
import useSocket from "hooks/useSocket";
import { useApiEndpoint } from "ApiEndpointContext";
import preMessages from "components/MyFileTabs/preMessagerie";
import { formatDateToFrench } from "functions";

function Time({ time }) {
  return <p className="text-xs text-center my-5">{formatDateToFrench(time)}</p>;
}
Time.propTypes = {
  time: PropTypes.string.isRequired,
};

function Message({ item, previousItem, currentUser }) {
  const isFile = !!item?.lien;
  const sender = isFile ? item?.proprietaire?.data : item?.user?.data;
  const previousSender = previousItem?.lien
    ? previousItem?.proprietaire?.data
    : previousItem?.user?.data;
  const isMe = sender.id === currentUser.id;
  const isPreviousSameSender = sender?.id === previousSender?.id;
  const senderName = isMe
    ? "Vous"
    : `${sender?.attributes?.firstname} ${sender?.attributes?.name}`;
  const formattedDate = formatDateToFrench(item?.createdAt);
  const previousFormattedDate = formatDateToFrench(previousItem?.createdAt);
  const isPreviousSameDate = previousFormattedDate === formattedDate;
  const contents = isFile ? (
    <>
      <AttachIcon className="inline-block" />
      <span className="mx-1">
        <a
          href={item?.lien}
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          {item?.nom}
        </a>
      </span>
    </>
  ) : (
    item?.contenu
  );

  return (
    <div>
      {!isPreviousSameDate && (
        <p className="text-xs text-center my-4">{formattedDate}</p>
      )}
      <div className={`mb-1 ${isMe ? "justify-end" : "justify-start"}`}>
        <div
          className={`grid ${isMe ? "justify-items-end" : "justify-items-start"}`}
        >
          {/* <p>Sender: {sender} currentUser: {currentUser}</p> */}
          {!isPreviousSameSender && (
            <p className="text-xs text-light-color mb-1">{senderName}</p>
          )}
          <div
            className={`rounded-md p-2 w-fit whitespace-pre-line max-w-[500px] text-sm ${isMe ? "bg-stone-300" : "bg-light-color"}`}
          >
            {contents}
          </div>
        </div>
      </div>
    </div>
  );
}
Message.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  previousItem: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

export default function Messagerie({ id, data }) {
  const { isCustomerUrl } = useConfig();
  const apiEndpoint = useApiEndpoint();
  const fileInputRef = useRef(null);
  const bottomEl = useRef(null);
  const [currentPreMessage, setCurrentPreMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const { token, user, role } = useAuth();
  const [fetchApi] = useFetchApi();
  const [messageValue, setMessageValue] = useState("");
  const [socket, socketIsAuthed] = useSocket();
  const isManager = !isCustomerUrl && role && role !== "Client";

  // Initialisation des messages et des documents depuis les données chargées
  useEffect(() => {
    const chatDocuments =
      data?.data?.attributes?.documents?.data?.filter(
        (document) => document.attributes.ajout_depuis_messagerie === true,
      ) || [];
    const chatMessages = data?.data?.attributes?.messages?.data || [];
    const combinedData = [...chatDocuments, ...chatMessages].sort(
      ({ attributes: messageA }, { attributes: messageB }) =>
        messageA.createdAt.localeCompare(messageB.createdAt),
    );
    setMessages(combinedData);
  }, [!!data?.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Défile automatiquement vers le bas quand de nouveaux messages sont ajoutés à la discussion
  useEffect(() => {
    bottomEl?.current?.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  useEffect(() => {
    // Rejoint le canal de discussion du dossier si authentifié
    const joinIfAuthed = () => {
      if (socketIsAuthed) {
        socket.emit("join", { dossier: id }, (error) => {
          console.warn("Erreur en rejoignant la messagerie :", error);
        });
      }
    };

    // Ajoute un nouveau message de discussion reçu par le socket
    const addMessage = (messageData, error) => {
      if (error) {
        console.warn("Error receiving message", error);
      }
      if (messageData) {
        setMessages((previousMessages) => [...previousMessages, messageData]);
      }
    };

    socket.on("message", addMessage);
    socket.on("connect", joinIfAuthed);
    socket.on("reconnect", joinIfAuthed);
    joinIfAuthed();

    return () => {
      socket.off("connect", joinIfAuthed);
      socket.off("reconnect", joinIfAuthed);
      socket.off("message", addMessage);
    };
  }, [socket, socketIsAuthed, id]);

  const renderChatContent = () => {
    return messages?.map(({ id, attributes: item }, index) => (
      <Message
        item={item}
        previousItem={index !== 0 ? messages[index - 1].attributes : {}}
        key={`${item.lien ? "file" : "message"}_${id}`}
        currentUser={user}
      />
    ));
  };

  const handleMessageInput = (e) => {
    setMessageValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      sendText();
    }
  };

  const sendText = async () => {
    if (messageValue) {
      const body = {
        data: {
          dossier: [id],
          contenu: messageValue.trim(),
        },
      };

      try {
        await fetchApi(`messages`, { body });
        setMessageValue("");
      } catch ({ message }) {
        console.warn("error sending message", message);
      }
    }
  };

  const sendFiles = async (e) => {
    const pdfFiles = e.target.files;

    if (pdfFiles.length === 0) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < pdfFiles.length; i++) {
      formData.append(`file${i + 1}`, pdfFiles[i]);
    }

    try {
      await fetch(
        `${apiEndpoint}/api/dossier/upload-documents-messagerie/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      );
    } catch (error) {
      console.warn("Erreur d'envoi du fichier :", error);
    }
  };

  return (
    <>
      <div className="min-h-[200px] flex flex-1 mt-4 flex-col rounded-xl border-0.5 border-stone-400 mb-4 overflow-y-hidden ">
        <div className="rounded-xl overflow-y-auto px-4 pb-4">
          {renderChatContent()}
          <div ref={bottomEl}></div>
        </div>
      </div>
      {isManager && (
        <div className="mx-4 mb-4 flex">
          <div>
            {Object.keys(preMessages).map((key) => (
              <button
                key={key}
                className={`text-xs ${key === currentPreMessage ? "bg-main-color text-white" : "bg-light-color"} px-3.5 py-2 rounded mx-3 my-1 ml-0`}
                onClick={() => {
                  setMessageValue(preMessages[key]);
                  setCurrentPreMessage(key);
                }}
              >
                {key}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="flex min-h-[80px]">
        <textarea
          placeholder="Tapez votre message"
          value={messageValue}
          rows="4"
          className="w-[100%] p-2 text-xs border-y-0.5 border-l-0.5 border-[#032B4E] rounded-l-md resize-none"
          onChange={handleMessageInput}
          onKeyDown={handleKeyPress}
        ></textarea>
        <button
          className="border-y-0.5 border-[#032B4E] w-[60px] flex items-center justify-center text-light-color bg-white"
          onClick={() => fileInputRef.current.click()}
        >
          <AttachIcon />
        </button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".pdf"
          onChange={sendFiles}
        />

        <button
          className="bg-main-color w-[70px] flex items-center justify-center rounded-r-md"
          onClick={sendText}
        >
          <SendIcon />
        </button>
      </div>
    </>
  );
}
Messagerie.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};
