import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { translateMessage } from "functions";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import useFetchApi from "hooks/useFetchApi";
import InfoTooltip from "components/InfoTooltip";

export default function FieldBlock({
  label,
  name,
  type = "text",
  value,
  id,
  route,
  sectionID,
  readonly,
  error: paramError,
  require,
  editAll,
  updateGroupAnswers,
  role,
  maxLength,
  minLength,
  decimals,
  nospace,
  fetchData,
  displayNone,
  tooltip,
  format,
}) {
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(value);
  const [fieldError, setFieldError] = useState();
  const error = fieldError ?? paramError;
  const isNumeric =
    type === "number" || type === "float" || type === "decimalnumber";
  const isLengthValid = !minLength || !answer || answer?.length >= minLength;
  const isRequireValid = !require || !!answer;
  const isValid = isLengthValid && isRequireValid;

  const onSubmit = async () => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    document.activeElement?.blur();
    const body = route
      ? { [route]: { [name]: answer || null, id: sectionID } }
      : { [name]: answer || null };

    try {
      setFieldError(null);
      await fetchApi(`update-dossier/${id}`, { method: "PUT", body });
      // Trigger a revalidation (refetch) of the "Dossiers details" list.
      fetchData();
    } catch ({ message }) {
      setFieldError(translateMessage(message));
    } finally {
      setAnswer(value);
    }
    setLoading(false);
    setEditing(false);
  };

  const onCancel = () => {
    setEditing(false);
    setAnswer(value);
  };

  const handleAnswer = (item) => {
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
  };

  const saveAnswer = editAll ? handleAnswer : setAnswer;

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  const inputProps = {
    type: type || "text",
    value: answer || "",
    onChange: (e) => {
      let value = e.target.value;
      if (nospace || isNumeric) {
        value = value.replace(/\s/g, "");
      }
      if (type === "number") {
        value = value.match(/(\d*)/)?.[1] ?? "";
      } else if (type === "float") {
        value = value.match(/(\d*\.?\d*)/)?.[1] ?? "";
      } else if (type === "decimalnumber") {
        value =
          value.match(
            new RegExp(
              `\\d{0,${maxLength || 10}}(\\.\\d{0,${decimals || 2}})?`,
              "g",
            ),
          )?.[0] ?? "";
      }
      saveAnswer(value);
    },
    autoFocus: !editAll,
    maxLength,
    minLength,
    disabled: loading,
  };

  if (isNumeric) {
    inputProps.type = "text";
    if (type === "decimalnumber") {
      delete inputProps.maxLength;
    }
  }

  const isEditing = (editing || editAll) && type !== "readonly";

  return (
    <div
      className={`flex justify-between border-b-2 pb-1 mt-1 pt-3 ${displayNone ? "hidden" : ""}`}
    >
      <div className="flex flex-col">
        <label className="text-xs">
          {label} {name === "volume_silo" && <span>(en litres)</span>}
          {tooltip && <InfoTooltip text={tooltip} />}
        </label>
        {!editing && !editAll && (
          <p className="w-[650px]">{format ? format(value) : value || "-"}</p>
        )}

        {isEditing && (
          <input
            {...inputProps}
            className="w-[570px] border-2 border-sky-600 rounded outline-none"
          />
        )}

        {error && <p className="text-xs text-red-500">{error}</p>}

        {!isLengthValid && isEditing && (
          <p className="text-red-500 text-xs mt-1">
            La saisie doit contenir
            {minLength !== maxLength ? " au moins " : " "}
            {minLength} caractères.
          </p>
        )}

        {!isRequireValid && !answer && isEditing && (
          <p className="text-red-500 text-xs mt-1">
            * Attention ce champ est obligatoire
          </p>
        )}
      </div>

      {!editAll && !readonly && (
        <div>
          {editing && role !== "Consultation" && (
            <div className="flex space-x-1">
              {!loading && (
                <>
                  <button onClick={onSubmit} disabled={!isValid}>
                    <img
                      src={iconConfirm}
                      alt="confirmer"
                      className="max-w-none"
                    />
                  </button>
                  <button onClick={onCancel}>
                    <img
                      src={iconCancel}
                      alt="annuler"
                      className="max-w-none"
                    />
                  </button>
                </>
              )}
              {loading && <LoadingIcon />}
            </div>
          )}

          {!editing && role !== "Consultation" && (
            <button
              onClick={() => {
                setEditing(true);
                setAnswer(value);
              }}
            >
              <img src={iconEdit} alt="modifier" className="max-w-none" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
FieldBlock.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  readonly: PropTypes.bool,
  error: PropTypes.any,
  require: PropTypes.bool,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  role: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  decimals: PropTypes.number,
  nospace: PropTypes.bool,
  fetchData: PropTypes.func,
  displayNone: PropTypes.bool,
  tooltip: PropTypes.string,
  format: PropTypes.func,
};
