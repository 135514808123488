import React from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import useSWR from "swr";
import useFetchApi from "hooks/useFetchApi";
import Loader from "components/Loader";

function NumberBlock({ num, unit, label, animated }) {
  return (
    <div>
      {label && <h2 className="text-xs text-slate-400">{label}</h2>}
      <div>
        <div className="text-main-color text-2xl font-semibold flex items-baseline">
          {animated && <AnimatedNumber n={num} />}

          {!animated && <span>{num.toLocaleString("fr-FR")}</span>}
          <span className="text-xs font-normal ml-1">{unit}</span>
        </div>
      </div>
    </div>
  );
}
NumberBlock.propTypes = {
  num: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  label: PropTypes.any,
  animated: PropTypes.bool,
};

function AnimatedNumber({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 100, friction: 10 },
  });
  return (
    <animated.div>
      {number.to((n) => n.toFixed(0).toLocaleString("fr-FR"))}
    </animated.div>
  );
}
AnimatedNumber.propTypes = {
  n: PropTypes.number.isRequired,
};

export default function Infographic({ layout }) {
  const [fetchApi] = useFetchApi();
  // const piePercent = 70;
  const { data, error, isLoading } = useSWR("indicateurs", fetchApi);

  if (!data) {
    return (
      <div>
        <h1 className="font-semibold text-2xl text-main-color mb-5">
          Indicateurs
        </h1>
        <div className="min-h-[250px] flex-1 flex justify-center items-center">
          {error && !isLoading ? (
            <div className="text-red-500">
              Une erreur est survenue: {error.message}
            </div>
          ) : null}
          {isLoading && (
            <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
          )}
        </div>
      </div>
    );
  } else {
    const pieStyle = {
      "--p": data?.data.indicators.simulation_demande ?? 0,
      "--c": "#D7EAFF",
    };
    return (
      <div className="w-100">
        <h1 className="font-semibold text-2xl text-main-color mb-5">
          Indicateurs
        </h1>
        <div className={layout === "home" ? "infographic-home" : "infographic"}>
          <div className="div1 border-stone-400 border-0.5 rounded-lg px-4 py-4 min-h-[235px]">
            <h1 className="text-base font-semibold">Simulation / Demande</h1>
            <h2 className="text-xs text-slate-400">Taux de transformation</h2>
            <div className="w-full flex justify-center">
              <div className="piechart">
                <div className="pie animate" style={pieStyle}>
                  <div className="reverse text-main-color font-bold font-Poppins flex">
                    <AnimatedNumber
                      n={data.data.indicators.simulation_demande ?? 0}
                    />{" "}
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div2 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Représentant
            </h1>
            <NumberBlock
              num={data.data.indicators.representant ?? 0}
              unit="€"
            />
          </div>
          <div className="div3 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Nombre de simulations à traiter
            </h1>
            <NumberBlock
              num={data.data.indicators.nombre_de_simulation_a_traiter ?? 0}
              unit=""
              animated={true}
            />
          </div>
          <div className="div4 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Total volume dossiers
            </h1>
            <NumberBlock
              num={data.data.indicators.total_volume_dossiers ?? 0}
              unit="kWhc"
            />
          </div>
          <div className="div5 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Fiche la plus demandée
            </h1>
            <h1
              className={`text-main-color font-semibold ${layout === "home" ? "text-2xl" : "text-md"}`}
            >
              {data.data.indicators.fiche_la_plus_demandee ?? "-"}
            </h1>
          </div>
          <div className="div6 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Volume dossiers ménages
            </h1>
            <div className="flex w-full justify-between">
              <NumberBlock
                num={
                  data.data.indicators.volume_dossiers_menages.classiques ?? 0
                }
                unit="kWhc"
                label="Classiques"
              />
              <NumberBlock
                num={data.data.indicators.volume_dossiers_menages.modestes ?? 0}
                unit="kWhc"
                label="Modestes"
              />
              <NumberBlock
                num={
                  data.data.indicators.volume_dossiers_menages.precaires ?? 0
                }
                unit="kWhc"
                label="Précaires"
              />
            </div>
          </div>
          <div className="div7 border-stone-400 border-0.5 rounded-lg px-4 py-4 flex justify-between flex-col">
            <h1 className="text-base font-semibold leading-[1.2rem]">
              Montant moyen
            </h1>
            <NumberBlock
              num={data.data.indicators.montant_moyen ?? 0}
              unit="€"
            />
          </div>
        </div>
        {error || isLoading ? (
          <Loader isLoading={isLoading} error={error} />
        ) : null}
      </div>
    );
  }
}
Infographic.propTypes = {
  layout: PropTypes.string,
};
