import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";

export default function FieldBlockCheckbox({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll,
  updateGroupAnswers,
  role,
  fetchData,
  displayNone,
}) {
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const [answer, setAnswer] = useState(!!value);

  const onSubmit = async () => {
    setEditing(!editing);
    document.activeElement?.blur();
    const body = route
      ? { [route]: { [name]: answer, id: sectionID } }
      : { [name]: answer };
    try {
      await fetchApi(`update-dossier/${id}`, { body, method: "PUT" });
      // Trigger a revalidation (refetch) of the "Dossiers details" list.
      mutate(`/api/dossiers/${id}?populate=*`);
    } catch ({ message }) {
      setAnswer(!!value);
    } finally {
      fetchData();
    }
  };

  const onCancel = () => {
    setEditing(!editing);
    setAnswer(value || false);
  };

  const handleAnswer = (item) => {
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
  };

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  return (
    <div
      className={`flex items-center justify-between  pb-1 mt-1 pt-1 border-b-2 pb-1 ${displayNone ? "hidden" : ""}`}
    >
      <div className="flex flex-col">
        <label className="text-xs flex items-center">
          <input
            type="checkbox"
            checked={!!answer}
            disabled={!editing && !editAll} // Disable the checkbox when not editing
            onChange={() => {
              setAnswer(!answer);
              if (editAll) {
                handleAnswer(!answer);
              }
            }}
          />
          <span className="ml-2">{label}</span>
        </label>
      </div>
      <div>
        {editing && !editAll && role !== "Consultation" && (
          <div className="flex space-x-1">
            <button onClick={onSubmit}>
              <img src={iconConfirm} alt="confirmer" />
            </button>
            <button onClick={onCancel}>
              <img src={iconCancel} alt="annuler" />
            </button>
          </div>
        )}

        {!editing && !editAll && role !== "Consultation" && (
          <button onClick={() => setEditing(!editing)}>
            <img src={iconEdit} alt="modifier" />
          </button>
        )}
      </div>
    </div>
  );
}
FieldBlockCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  role: PropTypes.string,
  fetchData: PropTypes.func,
  displayNone: PropTypes.bool,
};
