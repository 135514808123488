import React from "react";
import { useConfig } from "providers/ConfigProvider";
import PropTypes from "prop-types";
import PageMarkdown from "components/LegalPages/PageMarkdown";
import PageAccordion from "components/LegalPages/PageAccordion";

export default function LegalPage({ page }) {
  const { isCustomerUrl } = useConfig();

  switch (page) {
    case "cgu":
      return (
        <PageMarkdown
          filename={`${page}${(isCustomerUrl && "-client") || ""}.md`}
          title="Conditions générales d'utilisation"
        />
      );
    case "cgv":
      return (
        <PageMarkdown
          filename={`${page}.md`}
          title="Conditions générales de vente"
        />
      );
    case "faq":
      return <PageAccordion filename={`${page}.md`} title="F.A.Q." />;
    case "mention":
      return <PageMarkdown filename={`${page}.md`} title="Mentions légales" />;
    case "policy":
      return (
        <PageMarkdown
          filename={`${page}${(isCustomerUrl && "-client") || ""}.md`}
          title="Politique de confidentialité de la plateforme"
        />
      );
    default:
  }

  return null;
}
LegalPage.propTypes = {
  page: PropTypes.string.isRequired,
};
