import React from "react";
import PropTypes from "prop-types";
import logo from "images/logo_soregies.svg";
import { useConfig } from "providers/ConfigProvider";

const AuthLayout = ({ children }) => {
  const { isManagerUrl } = useConfig();

  const welcomeMessage = isManagerUrl
    ? "Bienvenue sur l’Espace Gestionnaire CEE"
    : "Bienvenue sur votre Espace bénéficiaire Sorégies";

  return (
    <div className="h-full flex flex-row items-center justify-center">
      <div className="w-[580px] bg-light-color py-10 flex flex-col l-4 pr-2 h-full items-center justify-center">
        <img
          className="static absolute top-12 left-16 mb-20"
          alt="logo Soregies"
          src={logo}
        />
        <h1 className="text-5xl font-medium text-main-color ml-8 w-[500px]">
          {welcomeMessage}
        </h1>
        {!isManagerUrl && (
          <p className="text-xl font-medium text-main-color ml-8 w-[500px] mt-8">
            Les primes économies d&apos;énergie
            <br />
            Une aide pour vos travaux
          </p>
        )}
      </div>
      <div className="flex-1 h-full bg-white overflow-y-auto flex flex-row m-auto">
        {children}
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
