import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";

export default function FieldBlockSelect({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll = false,
  updateGroupAnswers,
  role,
  fetchData,
  displayNone = false,
  multiple = false,
  options,
  emptyLabel = "Sélectionner",
}) {
  const [fetchApi] = useFetchApi();

  const availableOptions = useMemo(
    () =>
      options.map((option) =>
        typeof option === "string" ? { value: option, label: option } : option,
      ),
    [options],
  );
  const selectableOptions = useMemo(
    () => availableOptions.filter((item) => item?.selectable !== false),
    [availableOptions],
  );
  // const notSelectableOptions = useMemo(
  //   () => availableOptions.filter((item) => item?.selectable === false),
  //   [availableOptions],
  // );

  const formattedValue = useMemo(
    () =>
      multiple
        ? availableOptions.filter((option) => value?.includes(option.value))
        : availableOptions.find((option) => option.value === value),
    [multiple, availableOptions, value],
  );
  // const hasUnselectableValue = useMemo(
  //   () =>
  //     multiple
  //       ? notSelectableOptions.filter((option) => value?.includes(option.value))
  //       : notSelectableOptions.find((option) => option.value === value),
  //   [multiple, notSelectableOptions, value],
  // );

  const [editing, setEditing] = useState(false);
  const [answer, setAnswer] = useState(formattedValue);
  const onSubmit = async () => {
    setEditing(!editing);
    document.activeElement?.blur();
    const newValue = multiple
      ? answer.map(({ value }) => value)
      : answer?.value;
    const body = route
      ? { [route]: { [name]: newValue, id: sectionID } }
      : { [name]: newValue };
    try {
      await fetchApi(`update-dossier/${id}`, { body, method: "PUT" });
      // Trigger a revalidation (refetch) of the "Dossiers details" list.
      mutate(`/api/dossiers/${id}?populate=*`);
    } catch ({ message }) {
      setAnswer(formattedValue);
    } finally {
      fetchData();
    }
  };

  const onCancel = () => {
    setEditing(!editing);
    setAnswer(formattedValue);
  };

  const setGroupAnswer = (selectedValue) => {
    const groupSelectedValue = multiple
      ? selectedValue.map((option) => option.value)
      : selectedValue?.value;
    updateGroupAnswers(name, groupSelectedValue, route, sectionID);
  };

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  const displayValue = multiple ? (
    formattedValue.length > 0 ? (
      <ul>
        {formattedValue.map(({ value, label }) => (
          <li key={value}>{label}</li>
        ))}
      </ul>
    ) : (
      <p>{emptyLabel}</p>
    )
  ) : (
    <p>{formattedValue?.label || emptyLabel}</p>
  );

  return (
    <div
      className={`flex items-center justify-between border-b-2 pb-1 mt-1 pt-1 ${displayNone ? "hidden" : ""}`}
    >
      <div className="flex flex-col">
        <label className="text-xs">{label}</label>
        {!editing && !editAll && displayValue}

        {(editing || editAll) && (
          <Select
            placeholder={emptyLabel}
            value={answer}
            options={selectableOptions}
            isMulti={multiple}
            className="w-[570px] border-2 border-sky-600 rounded outline-none"
            onChange={(selectedValue) => {
              setAnswer(selectedValue);
              if (editAll) {
                setGroupAnswer(selectedValue);
              }
            }}
          />
        )}
      </div>
      <div>
        {editing && role !== "Consultation" && (
          <div className="flex space-x-1">
            <button onClick={onSubmit}>
              <img src={iconConfirm} alt="confirmer" className="max-w-none" />
            </button>
            <button onClick={onCancel}>
              <img src={iconCancel} alt="annuler" className="max-w-none" />
            </button>
          </div>
        )}

        {!editing && !editAll && role !== "Consultation" && (
          <button onClick={() => setEditing(!editing)}>
            <img src={iconEdit} alt="modifer" className="max-w-none" />
          </button>
        )}
      </div>
    </div>
  );
}
FieldBlockSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  role: PropTypes.string,
  fetchData: PropTypes.func,
  displayNone: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  emptyLabel: PropTypes.string,
};
