// TODO: Remplacer par useFetchApi

import { detectConfig } from "providers/ConfigProvider";

const { apiUrl } = detectConfig();

const errorTranslations = {
  "Invalid identifier or password": "Identifiant ou mot de passe incorrect.",
  "Failed to fetch": "Impossible de se connecter au serveur.",
  Forbidden: "Accès refusé.",
};

export async function fetchApi(apiPath, { token, body }) {
  try {
    const res = await fetch(`${apiUrl}${apiPath}`, {
      method: body ? "POST" : "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      body: body ? JSON.stringify(body) : undefined,
    });
    const data = await res.json();
    if (res.ok) {
      return data;
    }
    throw new Error(data.error.message);
  } catch ({ message }) {
    throw new Error(
      message in errorTranslations
        ? errorTranslations[message]
        : message ?? "Une erreur est survenue.",
    );
  }
}
