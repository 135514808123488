import React from "react";
import qs from "qs";
import { statutsDossierGestionnaireColors } from "constants/statutsDossier";

export const translateMessage = (message) => {
  switch (message) {
    case "Invalid identifier or password":
      return "Identifiant ou mot de passe incorrect.";
    case "Failed to fetch":
      return "Impossible de se connecter au serveur.";
    case "Forbidden":
      return "Accès refusé.";
    case "Passwords do not match":
      return "Veuillez saisir un mot de passe identique.";
    case "The provided current password is invalid":
      return "Le mot de passe actuel saisi est incorrect.";
    case "password must be at least 6 characters":
      return "Le mot de passe doit être au moins de 6 caractères.";
    case "Cannot read properties of undefined (reading 'jwt')":
      return "Identifiant ou mot de passe incorrect.";
    case "Cannot read properties of undefined (reading 'message')":
    case "res is undefined":
      return "Une erreur s'est produite veuillez réessayer ultérieurement.";
    case "Incorrect code provided":
    case "code is a required field":
      return "Code incorrect fourni, veuillez vérifier votre URL.";
    case "Email or Username are already taken":
      return "L'email est déjà enregistré, veuillez vous connecter.";
    case "Invalid token":
      return "Ce lien d'activation a expiré ou est invalide. Merci de recréer votre compte client.";
    case "E-mail address not found":
      return "Cette adresse e-mail n'est pas reconnue, veuillez la saisir de nouveau.";
    case "Not Found":
    case "Resource not found":
      return "Ressource non trouvée.";
    case "You are not allowed to access this resource":
      return "Vous n'avez pas l'autorisation d'accéder à cette ressource.";
    case "Simulation not found":
      return "Cette simulation n'a pas été trouvée.";
    case "Cannot request a simulation belonging to another user":
      return "Vous ne pouvez pas faire de demande pour une simulation appartenant à un autre utilisateur.";
    case "Conversion request already sent":
      return "Demande de transformation déjà envoyée.";
    case "email must be a valid email":
      return "Le format de l'adresse e-mail n'est pas valide.";
    case 'code_postal must match the following: "/\\b\\d{5}\\b/"':
      return "Le code postal doit comporter 5 chiffres.";
    default:
      return message;
  }
};

export const getStatusBackground = (status) => {
  return `${statutsDossierGestionnaireColors[status] ?? "bg-status-success"}`;
};

export const transformTimestamp = (timestamp) => {
  const dt = new Date(timestamp);
  const day = String(dt.getUTCDate()).padStart(2, "0");
  const month = String(dt.getUTCMonth() + 1).padStart(2, "0");
  const year = String(dt.getUTCFullYear()).slice(-2);

  return `${day}.${month}.${year}`;
};

/**
 * Transforme un objet de query params en query string.
 * @param {Object} queryParams
 * @returns {string}
 */
export function stringifyQueryParams(queryParams) {
  return qs.stringify(queryParams, {
    encodeValuesOnly: true, // prettify URL
  });
}

export function formatDate(dateStr) {
  if (!dateStr) {
    return "-";
  }
  const date = new Date(dateStr);
  // Note: We take the last 2 digits of the year as per design, to do so,
  // we simply take the remainder of the year divided by 100.
  return [date.getDate(), date.getMonth() + 1, date.getFullYear() % 100]
    .map((component) => component.toString().padStart(2, "0"))
    .join(".");
}

export function formatDateFull(dateStr) {
  if (!dateStr) {
    return "-";
  }
  const date = new Date(dateStr);
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map((component) => component.toString().padStart(2, "0"))
    .join("/");
}

export function formatDateToFrench(inputDateStr) {
  if (!inputDateStr) {
    return "";
  }

  // Create a Date object from the input date string
  const inputDate = new Date(inputDateStr);
  // Define arrays for day and month names in French
  const frenchDays = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const frenchMonths = [
    "jan",
    "fév",
    "mar",
    "avr",
    "mai",
    "juin",
    "juil",
    "aoû",
    "sep",
    "oct",
    "nov",
    "déc",
  ];

  // Get the day, month, and year from the Date object
  const day = inputDate.getDate();
  const month = frenchMonths[inputDate.getMonth()];
  // const year = inputDate.getFullYear();
  const dayName = frenchDays[inputDate.getDay()];

  // Get the current date
  const currentDate = new Date();
  // const currentDay = currentDate.getDate();
  // const currentMonth = currentDate.getMonth();
  // const currentYear = currentDate.getFullYear();

  // Check if the input date is today
  if (inputDate.toDateString() === currentDate.toDateString()) {
    return "Aujourd'hui";
  }

  // Construct the formatted date string
  const formattedDate = `${dayName} ${day} ${month}`;

  return formattedDate;
}

export function timeAgo(timestamp) {
  const currentDate = new Date();
  const pastDate = new Date(timestamp);
  const timeDifference = currentDate - pastDate;

  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = month * 12;

  if (timeDifference < minute) {
    return "À l'instant";
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `Il y a ${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `Il y a ${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (timeDifference < month) {
    const days = Math.floor(timeDifference / day);
    return `Il y a ${days} jour${days > 1 ? "s" : ""}`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return `Il y a ${months} mois`;
  } else {
    const years = Math.floor(timeDifference / year);
    return `Il y a ${years} an${years > 1 ? "s" : ""}`;
  }
}

export function formatNumber(number, fractionDigits = 2) {
  return number.toLocaleString("fr-FR", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

export function formatAmount(amount) {
  return amount || amount === 0 ? <>{formatNumber(amount)}&nbsp;€</> : "-";
}

export function formatFileSize(size) {
  if (size < 1024) {
    return <>{formatNumber(size, 0)}&nbsp;o</>;
  }
  if (size < 1024 * 1024) {
    return <>{formatNumber(size / 1024)}&nbsp;Ko</>;
  }
  return <>{formatNumber(size / 1024 / 1024)}&nbsp;Mo</>;
}

export function formatVolume(volume) {
  return volume || volume === 0 ? (
    <>{formatNumber(volume, 0)}&nbsp;kWhc</>
  ) : (
    "-"
  );
}

export function maxPlus(value, maxValue) {
  return value > maxValue ? `${maxValue}+` : `${value}`;
}
