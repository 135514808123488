import React, { useState, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";

export const FormContext = createContext();

export default function Form({ children, className, submit }) {
  const methods = useForm();
  const { handleSubmit } = methods;

  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const contextValue = useMemo(
    () => ({
      error,
      success,
      loading,
    }),
    [error, success, loading],
  );

  const onSubmit = async (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await submit(data);
      setSuccess(true);
    } catch ({ message }) {
      setError(message);
    }
    setLoading(false);
  };

  return (
    <FormContext.Provider value={contextValue}>
      <FormProvider {...methods}>
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
          {children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  submit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  children: null,
  className: "",
};
